export interface IStudyCreate {
    id?: number;
    projectId: string | undefined;
    studName: string;
    studCode?: string;
    microscopeSeries: string;
    studDescription: string;
    files: any[];
    remarks?: string;
    progress?: number;
    isActive?: boolean;
    status?: string;
}

export const emptyStudy: IStudyCreate = {
    projectId: '',
    studName: '',
    studCode: '',
    studDescription: '',
    microscopeSeries: "",
    files: [],
    remarks: '',
    isActive: true,
    status: ''
};
