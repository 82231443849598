import React, { useEffect } from 'react';
import Loading from './../../shared/Loading';
import { AppBreadcrumbContainer } from '../../shared/app-breadcrumb/app-breadcrumb.container';
import { AdminTable } from './component/admin-table';
export interface ExternalActionProps {
    getAdminTrails: () => void;
}

export interface ExternalProps {
    admins: any;
    isLoading: boolean;
}

export function AdminPage(props: ExternalActionProps & ExternalProps) {
    const { getAdminTrails, isLoading, admins } = props;

    useEffect(() => {
        getAdminTrails()
    }, []);

    return (
        <React.Fragment>
            <Loading loading={isLoading} />
            <AppBreadcrumbContainer breadcrumbs={[]} currentItem={'Admin Logs'} isUploading={false}></AppBreadcrumbContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
                
            </div>
            <AdminTable admins={admins} />
        </React.Fragment>
    );
}