import { combineReducers } from 'redux';
import { projectReducer as projectPage } from '../api/project/projectReducer';
import { studyReducer as studyPage } from '../api/study/studyReducer';
import { masterReducer as masterPage } from '../api/master/masterReducer';
import { usermngtReducer as usermngtPage } from '../api/usermanagement/usermngtReducer';
import { auditReducer as auditPage } from '../api/audit/auditReducer';
import { adminReducer as adminPage } from '../api/admin/adminReducer';
import { userReducer as userPage } from '../api/user/userReducer';
const appReducer = combineReducers({
    projectPage,
    studyPage,
    masterPage,
    usermngtPage,
    auditPage,
    adminPage,
    userPage
});

export default appReducer;