import { UsermanagementPage } from './usermngt.component';
import { connect } from 'react-redux';
import { getUsers, createUser, updateUser, clearErrors } from "../../api/usermanagement/usermngtAction";

const mapDispatchToProps = {
    getUsers,
    createUser,
    updateUser,
    clearErrors
};

const mapStateToProps = (state: any) => ({
    error: state.usermngtPage.isError,
    isLoading: state.usermngtPage.isLoading,
    users: state.usermngtPage.users,
    isSaved: state.usermngtPage.isSaved,
    isExists: state.usermngtPage.isExists,
    errors: state.usermngtPage.errors,
    isClearErrors: state.usermngtPage.isClearErrors,
    role: state.userPage.role
});

export const UsermanagementContainer = connect(mapStateToProps, mapDispatchToProps)(UsermanagementPage);
