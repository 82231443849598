export const GET_TECHNIQUES = "GET_TECHNIQUES";
export const GET_TECHNIQUES_FAILURE = "GET_TECHNIQUES_FAILURE";
export const GET_TECHNIQUES_SUCCESS = "GET_TECHNIQUES_SUCCESS";

export const GET_MOLECULES = "GET_MOLECULES";
export const GET_MOLECULES_FAILURE = "GET_MOLECULES_FAILURE";
export const GET_MOLECULES_SUCCESS = "GET_MOLECULES_SUCCESS";

export const GET_CQAS = "GET_CQAS";
export const GET_CQAS_FAILURE = "GET_CQAS_FAILURE";
export const GET_CQAS_SUCCESS = "GET_CQAS_SUCCESS";

export const GET_MICROSCOPE_SERIES = "GET_MICROSCOPE_SERIES";
export const GET_MICROSCOPE_SERIES_FAILURE = "GET_MICROSCOPE_SERIES_FAILURE";
export const GET_MICROSCOPE_SERIES_SUCCESS = "GET_MICROSCOPE_SERIES_SUCCESS";
