import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { ProjectTable } from './component/project-table';
import { ProjectDialog } from './component/project-dialoge';
import { ProjectDeleteDialog } from './component/project-delete-dialoge';
import AddIcon from '@mui/icons-material/Add';
import Loading from './../../shared/Loading';
import { IProjectCreate, emptyProject } from '../../model/project';
import axios from 'axios';
import { Typography } from '@mui/material';
import { specialSymbolsValidator, startWithANumberValidator, numberWithSpaceValidator } from '../../utitlities/FormValidator';
import { roles } from '../../utitlities/roles';

const apiUrl = process.env.REACT_APP_API_URL;
export interface ExternalActionProps {
  getProjects: () => void;
  getProject: (x: IProjectCreate) => void;
  createProject: (x: IProjectCreate) => void;
  updateProject: (x: IProjectCreate) => void;
}

export interface ExternalProps {
  projects: any;
  isLoading: boolean;
  isSaved: boolean;
  isError: boolean;
  role: string;
}

const EmptyError = {
  name: {
    isError: false,
    text: "",
  },
  description: {
    isError: false,
    text: "",
  },
  remarks: {
    isError: false,
    text: "",
  }
}

export function ProjectPage(props: ExternalActionProps & ExternalProps) {
  const { getProjects, isLoading, projects, createProject, isSaved, isError, updateProject, role } = props;
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(emptyProject);
  const [error, setError] = useState<any>(EmptyError);

  const onClose = () => {
    setSelected(emptyProject);
    setOpen(false);
    setError(EmptyError);
  }

  const onCloseDelete = () => {
    setSelected(emptyProject);
    setOpenDelete(false);
    setError(EmptyError);
  }

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (isSaved && !isLoading) {
      getProjects();
      onClose();
      onCloseDelete();
    }
  }, [isSaved]);

  useEffect(() => {
    if (isError && !isLoading) {
      onClose();
      onCloseDelete();
    }
  }, [isError]);

  const validate = (data: IProjectCreate) => {
    let isInvalid = false;
    let errorNew: any = {};
    if (data.projName.trim().length === 0) {
      errorNew.name = {
        isError: true,
        text: "Study name is mandatory"
      };

      isInvalid = true;
    } else if (data.projName.trim().length < 3 || data.projName.trim().length > 50) {
      errorNew.name = {
        isError: true,
        text: "Study name must contain minimum 3 characters in length & maximum 50 characters allowed"
      };

      isInvalid = true;
    }
    if (data.projDescription.trim().length === 0) {
      errorNew.description = {
        isError: true,
        text: "Study description is mandatory"
      };

      isInvalid = true;
    } else if (data.projDescription.trim().length < 10 || data.projDescription.trim().length > 200) {
      errorNew.description = {
        isError: true,
        text: "Study description must contain minimum 10 characters in length & maximun 200 characters allowed"
      };

      isInvalid = true;
    }
    if (data.id && typeof data.remarks === 'string' && data.remarks.trim().length === 0) {
      errorNew.remarks = {
        isError: true,
        text: "Remarks is mandatory"
      };

      isInvalid = true;
    } else if (data.id && ((data?.remarks && data.remarks.trim().length < 10) || (data?.remarks && data.remarks.trim().length > 200))) {
      errorNew.remarks = {
        isError: true,
        text: "Remarks must contain minimum 10 characters in length & maximun 200 characters allowed"
      };

      isInvalid = true;
    }
    if (!isInvalid) {
      if (data.id) {
        checkIfProjectExists(data, updateProject);
      } else {
        checkIfProjectExists(data, createProject);
      }
    } else {
      setError(errorNew);
    }
  }

  const checkIfProjectExists = (projectData: IProjectCreate, cb: (projectData: IProjectCreate) => void) => {
    let errorNew: any = {};
    const header = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    let queryParams = `projectName=${projectData.projName}`;
    if (projectData.id) {
      queryParams += `&id=${projectData.id}`;
    }

    axios.get(`${apiUrl}/project/is-exist?${queryParams}`, header)
      .then((response) => {
        cb(projectData);
      })
      .catch(function (error) {
        errorNew.name = {
          isError: true,
          text: "Study name already exists"
        };
        setError(errorNew);
      });
  }

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
        <Typography variant="h6">Study List</Typography>
        {(role == roles.EXPERT_USER) || (role == roles.SUPER_ADMIN) ?
          <Button className='button-text' variant="contained" style={{background: '#084056' }} color="primary" onClick={() => setOpen(true)}>
            <AddIcon /> Create Study
          </Button>
          : ''}
      </div>

      <ProjectTable
        projects={projects}
        setSelected={setSelected}
        setOpen={() => setOpen(true)}
        setOpenDelete={setOpenDelete}
        role={role}
      />
      {open &&
        <ProjectDialog
          open={open}
          onClose={onClose}
          selected={selected}
          onSave={validate}
          setSelected={setSelected}
          error={error}
          setError={setError}
        />
      }
      {openDelete &&
        <ProjectDeleteDialog
          open={openDelete}
          onClose={onCloseDelete}
          selected={selected}
          deactivateProject={validate}
          error={error}
          setError={setError}
          setSelected={setSelected}
        />
      }
    </React.Fragment>
  );
}