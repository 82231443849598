import * as actionType from "./masterActionType";

export interface MasterReducerState {
    isLoading: boolean;
    isError: boolean;
    techniques: [];
    cqas: [];
    molecules: [];
    microscopeSeries: [];
}

const initialState: MasterReducerState = {
  isError: false,
  isLoading: false,
  techniques: [],
  cqas: [],
  molecules: [],
  microscopeSeries: []
};

export const masterReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_TECHNIQUES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_TECHNIQUES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_TECHNIQUES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                techniques: action.payload ? action.payload.data : [],
            };
        case actionType.GET_CQAS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_CQAS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_CQAS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                cqas: action.payload ? action.payload.data : [],
            };
        case actionType.GET_MOLECULES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_MOLECULES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_MOLECULES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                molecules: action.payload ? action.payload.data : [],
            };
        case actionType.GET_MICROSCOPE_SERIES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_MICROSCOPE_SERIES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_MICROSCOPE_SERIES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                microscopeSeries: action.payload ? action.payload.data : [],
            };
        
        default:
            return state
    }
}
