import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IStudyCreate } from '../../../model/study';

export interface ExternalActionProps {
  onClose: () => void;
  deactivateStudy: (x: IStudyCreate) => void;
  setError: (x: any) => void;
  setSelected: (x: IStudyCreate) => void;
}

export interface ExternalProps {
  open: boolean;
  error: any;
  selected: IStudyCreate;
}

const EmptyError = {
  name: {
    isError: false,
    text: "",
  },
  description: {
    isError: false,
    text: "",
  },
  remarks: {
    isError: false,
    text: "",
  }
}

export function StudyDeleteDialog(props: ExternalActionProps & ExternalProps) {
  const { open, onClose, selected, deactivateStudy, setSelected, error, setError } = props;

  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
        <DialogTitle sx={{ backgroundColor: '#084056', color: '#fff', marginBottom: '10px' }}>{selected.isActive ? "Deactivate Step" : "Activate Step"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to {selected.isActive ? "deactivate" : "activate"}?
          </DialogContentText>
          <TextField
            error={error != null && error.remarks && error.remarks.isError}
            multiline
            margin="dense"
            id="remarks"
            label="Remarks *"
            type="text"
            rows={4}
            helperText={error != null && error.remarks && error.remarks.isError && error.remarks.text}
            value={selected.remarks}
            onChange={e => {
              setError(EmptyError);
              setSelected({
                ...selected,
                remarks: e.target.value
              });
            }}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button  style={{color: '#084056' }} className='button-text' onClick={() => onClose()}>No</Button>
          <Button
            onClick={() => {
              deactivateStudy({ ...selected, isActive: !selected.isActive });
            }}
            
            className='button-text'
            variant="contained"
            color="primary"
            style={{background: '#084056' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}