import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProjectContainer } from './views/project/project.container';
import { StudyContainer } from './views/study/study.container';
import { StudyResultContainer } from './views/study-result/study-result.container';
import { UsermanagementContainer } from './views/usermanagement/usermngt.container';
import { AdminContainer } from './views/admin-logs/admin.container';
import { AuditContainer } from './views/audit-trail/audit.container';
import { LoginContainer } from './views/login/login.container';
import { LandingPageContainer } from './views/landing/landing-page';
import { AppGuardedRoute } from './shared/app-router-guard/app-router-guard-container';
import { roles } from './utitlities/roles';
import Unauthorized from './shared/Unauthorized';
import {LoginFormContainer} from './views/login/login-form';
const role = JSON.parse(localStorage.getItem('app-user-details') || "{}")?.role;

export const RoutePath = (): React.ReactElement<void> => (
  <Routes>
    <Route path='/' element={<AppGuardedRoute>
     
      { 
      role == roles.TIBIL_IT ?
        <UsermanagementContainer />
        : (role == roles.EXPERT_USER) || (role == roles.USER) || (role == roles.SUPER_ADMIN) ?
          <ProjectContainer /> : <Unauthorized />}

    </AppGuardedRoute>} />
    <Route path='/projects' element={<AppGuardedRoute><ProjectContainer /></AppGuardedRoute>} />
    <Route path='/study/:id' element={<AppGuardedRoute><StudyContainer /></AppGuardedRoute>} />
    <Route path='/study/result/:id' element={<AppGuardedRoute><StudyResultContainer /></AppGuardedRoute>} />
    <Route path='/user-administration' element={<AppGuardedRoute><UsermanagementContainer /></AppGuardedRoute>} />
    <Route path='/admin-logs' element={<AppGuardedRoute><AdminContainer /></AppGuardedRoute>} />
    <Route path='/audit-trail' element={<AppGuardedRoute><AuditContainer /></AppGuardedRoute>} />
    <Route path='/landing-page' element={<LandingPageContainer />} />
    <Route path='/login' element={
        process.env.REACT_APP_IS_SSO == "true" ? <LoginFormContainer /> : <LoginContainer />
      } />
    <Route path='*' element={<Unauthorized />} />
  </Routes>
);

