
import { connect } from 'react-redux';
import GuardedRoute from './app-router-guard-component';
import { logout } from '../../api/user/userAction';

const mapDispatchToProps = {
    logout
};

const mapStateToProps = (state: any) => ({
    isLoggedIn: state.userPage.isLoggedIn,
    role: state.userPage.role
});

export const AppGuardedRoute = connect(mapStateToProps, mapDispatchToProps)(GuardedRoute);