import * as actionType from "./auditActionType";

export interface AuditReducerState {
    isLoading: boolean;
    isError: boolean;
    audits: [];
}

const initialState: AuditReducerState = {
    isError: false,
    isLoading: false,
    audits: []
};

export const auditReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_AUDIT:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_AUDIT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                audits: action.payload ? action.payload.data : [],
            };
        case actionType.GET_AUDIT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };

        default:
            return state
    }
}
