import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { IUsermngtCreate } from '../../../model/usermngt';
import Switch from '@mui/material/Switch';
import { IconButton, Tooltip } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export interface ExternalActionProps {
    setSelected: (x: IUsermngtCreate) => void;
    setOpen: (x: boolean) => void;
    setOpenDelete: (x: boolean) => void;
}

export interface ExternalProps {
    users: any;
}

export function UserManagementTable(props: ExternalActionProps & ExternalProps) {
    const { users, setOpen, setSelected, setOpenDelete } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ wordBreak: 'break-word' }}>
                            <TableCell>MUID</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell style={{ width: '75px' }}></TableCell>
                            <TableCell style={{ width: '75px' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users && Array.isArray(users) && users.length > 0 ? (
                            users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={row.isActive ? { backgroundColor: '#fff' } : { backgroundColor: '#eaeaea' }}
                                    hover
                                >
                                    <TableCell style={{ wordBreak: 'break-word' }} align='center' component="th" scope="row">
                                        {row.muid}
                                    </TableCell>
                                    <TableCell style={{ wordBreak: 'break-word' }} align='center'>{row.firstName}</TableCell>
                                    <TableCell style={{ wordBreak: 'break-word' }} align='center'>{row.lastName}</TableCell>
                                    <TableCell style={{ wordBreak: 'break-word' }} align='center'>{row.email}</TableCell>
                                    <TableCell style={{ wordBreak: 'break-word' }} align='center'>
                                        <span>{row.roleDesc}</span>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Tooltip title="Edit">
                                            <IconButton sx={row.isActive ? { color: '#000', cursor: "pointer" } : { color: "#9a9a9a" }}
                                                onClick={() => {
                                                    if (row.isActive) {
                                                        setSelected({
                                                            id: row.id,
                                                            muid: row.muid,
                                                            firstName: row.firstName,
                                                            lastName: row.lastName,
                                                            email: row.email,
                                                            role: row.roleDesc,
                                                            isActive: row.isActive,
                                                            remarks: ""
                                                        });
                                                        setOpen(true);
                                                    }
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Tooltip title="Enable/Disable">
                                            <Switch
                                                {...label}
                                                checked={row.isActive}
                                                onChange={() => {
                                                    setSelected({
                                                        id: row.id,
                                                        muid: row.muid,
                                                        firstName: row.firstName,
                                                        lastName: row.lastName,
                                                        email: row.email,
                                                        role: row.roleDesc,
                                                        isActive: row.isActive,
                                                        remarks: ""
                                                    });
                                                    setOpenDelete(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={7} align="center">
                                    No Users Found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users && Array.isArray(users) ? users.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </React.Fragment>
    );
}