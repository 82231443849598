import { Breadcrumbs, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBreadcrumb } from '../../model/breadcrumb';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export interface ExternalProps {
    breadcrumbs: IBreadcrumb[];
    currentItem: string;
    isUploading: boolean;
};

export default function AppBreadcrumb(props: ExternalProps) {
    const { breadcrumbs, currentItem, isUploading } = props;
    const navigate = useNavigate();
    const openInNewTab = () => {
        var c = document.createElement("a");
        c.href = process.env.REACT_APP_BASE_HREF || '/';
        c.target = "_blank";
        c.click();
    }
    return (
        <div className='d-flex align-items-center' style={{ marginBottom: '10px' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <HomeIcon sx={{ marginTop: '3px' }} />
                </Link>

                {breadcrumbs && Array.isArray(breadcrumbs) && breadcrumbs.length > 0 && (
                breadcrumbs.map((item: IBreadcrumb, index: number) => (
                    (item.path ? (
                        <Link key={index} underline="hover" color="inherit" href={item.path} sx={{ cursor: 'pointer' }}>
                            {item.text}
                        </Link>
                    ) : (
                        <Link
                            key={index}
                            underline="hover"
                            color="inherit"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate(item.delta as number)}
                        >
                            {item.text}
                        </Link>
                    ))
                )))}
                <Typography color="text.primary">{currentItem}</Typography>
            </Breadcrumbs>
        </div>
    );
}
