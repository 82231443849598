import { HashRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppRootContainer } from './../shared/app-root/app-root.container';
import theme from './../theme/theme';
import store from './store';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { WebSocketContainer } from '../shared/app-socket/web-socket.container';

toast.configure();

export default function App() {

  return (
    <Provider store={store}>
      <WebSocketContainer>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HashRouter>
            <AppRootContainer />
          </HashRouter>
        </ThemeProvider>
      </WebSocketContainer>
    </Provider>
  );
}
