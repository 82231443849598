import { FormControl } from "@mui/material";
export const StudyUpload = (props) => {
    const { error, setError, selected, setSelected } = props;
    const upload = async (fileEvent) => {
        if (fileEvent.target.files && fileEvent.target.files.length > 0) {
            validateFileUpload(filterFiles(fileEvent.target.files));
        } else {
            setError(Object.assign(error, {
                fileUpload: {
                    isError: true,
                    text: "Please choose the folder",
                }
            }));

            setSelected({
                ...selected,
                files: []
            });
        }
    };

    const filterFiles = (files) => {
        let filteredFiles = [];

        for (const file of files) {
            if (file.type === 'image/png' || file.type === 'image/tif' || file.type === 'image/tiff') {
                filteredFiles.push(file);
            }
        }

        return filteredFiles;
    }

    const validateFileUpload = (files) => {
        if (!validateFileNamingConvention(files)) {
            setError({
                ...error,
                fileUpload: {
                    isError: true,
                    text: `Foldername/Filenames should contain only alphanumerics, hyphen, underscore and space.`,

                }
            });

            setSelected({
                ...selected,
                files: []
            });
        }
        else if (!validatePlatewiseFileCount(files)) {
            setError({
                ...error,
                fileUpload: {
                    isError: true,
                    text: `Total file count must be multiple of 96 but got ${files.length} files`,

                }
            });

            setSelected({
                ...selected,
                files: []
            });
        }
        else {
            setError(Object.assign(error, {
                fileUpload: {
                    isError: false,
                    text: "",
                }
            }));

            setSelected({
                ...selected,
                files
            });
        }
    };

    const validateFileNamingConvention = (files) => {
        for (const file of files) {
            if (!/[\w\- ]+(_TM_p)\d+_\w+(.png|.tif|.tiff)/gi.test(file.name)) {
                return false;
            }
            if (/[^A-Za-z0-9 _-]/g.test(file.name.split(".")[0])) {
                return false;
            }
        }
        return true;
    };

    const validatePlatewiseFileCount = (files) => {
        const plateWiseFileCount = getFileCountByPlateWise(files);
        let invalidPlateCodes = [];

        invalidPlateCodes = Object.keys(plateWiseFileCount).filter(plateCode => plateWiseFileCount[plateCode] !== 96);

        return invalidPlateCodes.length === 0;
    };

    const getFileCountByPlateWise = (files) => {
        const plateWiseFileCount = {};

        for (const file of files) {
            const plate = getPlateCode(file.name);
            plateWiseFileCount[plate] = plateWiseFileCount[plate] ? ++plateWiseFileCount[plate] : 1;
        }

        return plateWiseFileCount;
    };

    const getPlateCode = (fileName) => {
        const regex = /_p\d+_/g;
        const plateCodeMatchString = fileName.match(regex);
        return plateCodeMatchString[plateCodeMatchString.length - 1].slice(1, -1);
    };

    return (
        <>
            <FormControl fullWidth sx={{ marginTop: '10px' }}>
                <div style={{ margin: '10px 0' }}>
                    <label style={{ marginRight: '20px' }}>File to be upload *</label>
                    <input type="file" onChange={upload} directory="true" webkitdirectory="true" multiple />
                </div>
            </FormControl>
            <span style={{ color: '#d32f2f', fontSize: '0.75rem', lineHeight: 2, letterSpacing: '0.03333em' }}>
                {error != null && error.fileUpload && error.fileUpload.isError && error.fileUpload.text}
            </span>
        </>
    );
}
