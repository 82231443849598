import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IStudyCreate } from '../../../model/study';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { StudyUpload } from './study-upload';
import { AppNameInputField } from '../../../shared/custom-fields/app-name-input-field';
import { AppDescriptionOrRemarksField } from '../../../shared/custom-fields/app-description-remarks-field';

export interface ExternalActionProps {
  onClose: () => void;
  setSelected: (x: IStudyCreate) => void;
  setError: (x: any) => void;
  createStudy: (x: IStudyCreate) => void;
}

export interface ExternalProps {
  open: boolean;
  error: any;
  selected: IStudyCreate;
  microscopeSeries: any;
}

export function StudyDialog(props: ExternalActionProps & ExternalProps) {
  const {
    open,
    onClose,
    selected,
    setSelected,
    createStudy,
    error,
    setError,
    microscopeSeries,
  } = props;

  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
        <DialogTitle sx={{ backgroundColor: '#084056', color: '#fff', marginBottom: '10px' }}>{selected.id ? "Update Step" : "Create Step"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          {selected.id && (
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>{selected.studCode}</Typography>
          )}
          <AppNameInputField
            autoFocus={true}
            error={error.name}
            id="name"
            label="Name *"
            value={selected.studName}
            clearErrors={() => setError({
              ...error,
              name: {
                text: '',
                isError: false
              }
            })}
            onChange={(value: string) => {
              setSelected({
                ...selected,
                studName: value
              });
            }}
          />
          <AppDescriptionOrRemarksField
            error={error.description}
            id="description"
            label="Description *"
            value={selected.studDescription}
            clearErrors={() => setError({
              ...error,
              description: {
                text: '',
                isError: false
              }
            })}
            onChange={(value: string) => {
              setSelected({
                ...selected,
                studDescription: value
              });
            }}
          />
          <FormControl fullWidth sx={{ marginTop: '10px' }}>
            <InputLabel id="microscope_series">Microscope Series *</InputLabel>
            <Select
              error={error != null && error.microscopeSeries && error.microscopeSeries.isError}
              fullWidth
              labelId="microscope_series"
              id="cqa-multi-checkbox"
              value={selected.microscopeSeries}
              onChange={e => {
                setError(Object.assign(error, {
                  microscopeSeries: {
                    isError: false,
                    text: "",
                  }
                }));
                setSelected({
                  ...selected,
                  microscopeSeries: e.target.value
                })
              }}
              label="Microscope Series *"
            >
              {microscopeSeries && microscopeSeries.map((microscopeSeries: any) => {
                return <MenuItem key={microscopeSeries.id} value={microscopeSeries.id}>{microscopeSeries.msName}</MenuItem>
              })
              }
            </Select>
            <span style={{ color: '#d32f2f', fontSize: '0.75rem', lineHeight: 2, letterSpacing: '0.03333em', marginLeft: '14px' }}>
              {error != null && error.microscopeSeries && error.microscopeSeries.isError && error.microscopeSeries.text}
            </span>
          </FormControl>
          {(!selected.id || (selected.status == "Upload Failed" || selected.status === "Failed" || selected.status === "Error")) && (
            <>
              <StudyUpload error={error} setError={setError} selected={selected} setSelected={setSelected} />
              <Typography style={{ color: '#d32f2f', fontSize: '0.75rem' }}>Note: </Typography>
              <Typography style={{ color: "#5a5a5a", fontSize: "12px" }}>
                The image should be of minimum 4k*4k resolution
              </Typography>
            </>
          )}
          {selected.id && (
            <AppDescriptionOrRemarksField
              error={error.remarks}
              id="remarks"
              label="Remarks *"
              value={selected.remarks}
              clearErrors={() => setError({
                ...error,
                remarks: {
                  text: '',
                  isError: false
                }
              })}
              onChange={(value: string) => {
                setSelected({
                  ...selected,
                  remarks: value
                });
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button style={{color: '#084056' }} className='button-text' onClick={() => onClose()}>Cancel</Button>
          <Button
            onClick={() => {
              createStudy(selected);
            }}
            className='button-text'
            variant="contained"
            color="primary"
            style={{background: '#084056' }}
          >
            {selected.id ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}