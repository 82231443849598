import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { StudyResultView } from './component/study-result-view';
import { CommitReportDialog } from './component/commit-report-dialoge';
import { UpdateCellDialog } from './component/update-cell-dialoge';
import { AppBreadcrumbContainer } from '../../shared/app-breadcrumb/app-breadcrumb.container';
import { IBreadcrumb } from '../../model/breadcrumb';
import { useParams } from "react-router-dom";
import Loading from './../../shared/Loading';
import { roles } from '../../utitlities/roles';

export interface ExternalActionProps {
  getStudy: (x: any, callFetchProcessedStudy: boolean) => void;
  updateWell: (x: any) => void;
  commitStudy: (x: any) => void;
  downloadStudy: (x: any) => void;
  getSignedUrl: (x: string, y: string) => void;
  downloadWholeStudy: (x: any) => void;
}

export interface ExternalProps {
  processedStudy: any;
  wellUpdated: boolean;
  singleStudy: any;
  signedUrl: any;
  isLoading: boolean;
  role: string;
}

export function StudyResultPage(props: any) {
  const { getSignedUrl, signedUrl, processedStudy, wellUpdated, updateWell, commitStudy, downloadStudy, getStudy, singleStudy, isLoading, role, downloadWholeStudy } = props;
  const [selected, setSelected] = React.useState<any>(null);
  const [open, setOpen] = useState(false);
  const [cellOpen, setCellOpen] = useState(false);
  const [re, setRe] = useState('');
  const [selectedPlate, setSelectedPlate] = React.useState<any>({ i: 0, show: false });
  const { id } = useParams();
  const breadcrumbs: IBreadcrumb[] = [
    {
      text: 'Step',
      delta: -1
    }
  ];

  const downloadFile = (code: any) => {
    downloadWholeStudy({ studyCode: code });
  }

  const downloadImage = (url: any) => {
    var c = document.createElement("a");
    c.href = url;
    c.target = "_blank";
    c.download = "";
    c.click();
  }

  useEffect(() => {
    getStudy(id, true);
  }, []);

  useEffect(() => {
    if (wellUpdated) {
      getStudy(id, true);
    }
  }, [wellUpdated]);

  useEffect(() => {
    if (selected && selected.si >= 0 && Array.isArray(processedStudy[selectedPlate.i].data)) {
      setSelected({ ...processedStudy[selectedPlate.i].data[selected.si], si: selected.si })
      setRe(processedStudy[selectedPlate.i].data[selected.si].updatedPrediction ? processedStudy[selectedPlate.i].data[selected.si].updatedPrediction : processedStudy[selectedPlate.i].data[selected.si].predictedValue);
    }
  }, [processedStudy]);


  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <AppBreadcrumbContainer breadcrumbs={breadcrumbs} currentItem={'Step Result'} isUploading={false}></AppBreadcrumbContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '40px', paddingRight: '40px' }}>
        <Typography variant="h6">
          {singleStudy && singleStudy.studCode} - {singleStudy.studName}
        </Typography>
        <div>
          {(role == roles.EXPERT_USER) || (role == roles.SUPER_ADMIN) || (role == roles.USER) ?
            <Button style={{background: '#084056' }} className='button-text' onClick={() => setOpen(true)} disabled={singleStudy.status === "Committed"} variant="contained" color="primary" sx={{ marginRight: '5px' }}>
              {singleStudy.status === "Committed" ? "Step Committed" : "Commit Step"}
            </Button>
            : ''}
          <Button style={{background: '#084056' }} className='button-text' variant="contained" onClick={() => downloadFile(singleStudy.studCode)}>Download All Reports</Button>
        </div>
      </div>
      <StudyResultView
        setCellOpen={setCellOpen}
        setRe={setRe}
        processedStudy={processedStudy}
        selected={selected}
        setSelected={setSelected}
        studyCode={singleStudy.studCode}
        studyStatus={singleStudy.status}
        selectedPlate={selectedPlate}
        setSelectedPlate={setSelectedPlate}
        getSignedUrl={getSignedUrl}
        signedUrl={signedUrl}
        isLoading={isLoading}
        downloadImage={downloadImage}
        role={role}
        downloadStudy={downloadStudy}
      />
      <CommitReportDialog
        open={open}
        onClose={() => setOpen(false)}
        commitStudy={commitStudy}
        studyId={singleStudy.studCode}
      />
      <UpdateCellDialog
        open={cellOpen}
        re={re}
        onClose={() => {
          setCellOpen(false);
        }}
        selected={selected}
        updateWell={updateWell}
      />
    </React.Fragment>
  );
}