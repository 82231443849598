export interface IUsermngtCreate {
    id?: string;
    muid: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    remarks?: string;
    isActive?: boolean
}

export const emptyUsermngt: IUsermngtCreate = {
    muid: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    remarks: '',
    isActive: true,
};
