import * as actionType from "./usermngtActionType";

export interface UserReducerState {
    isLoading: boolean;
    isError: boolean;
    users: [];
    isSaved: boolean;
    isExists: boolean;
    errors: string[];
}

const initialState: UserReducerState = {
    isError: false,
    isLoading: false,
    users: [],
    isSaved: false,
    isExists: false,
    errors: [],
};

export const usermngtReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.CREATE_USER:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.CREATE_USER_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
                errors: action.payload ? action.payload.data : [],
            };
        case actionType.CREATE_USER_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true,
            };
        case actionType.UPDATE_USER:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.UPDATE_USER_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.UPDATE_USER_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true,
            };
        case actionType.GET_USERS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_USERS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                users: action.payload ? action.payload.data : [],
            };
        case actionType.GET_USERS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.CLEAR_ERRORS:
            return {
                ...state,
                errors: [],
                isError: false
            };
        default:
            return state
    }
}
