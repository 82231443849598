import * as actionTypes from "./projectActionType";
import axios from 'axios';
import { IProjectCreate } from "../../model/project";
import Swal from 'sweetalert2';
import store from '../../app/store';

const apiUrl = process.env.REACT_APP_API_URL;
const userId = store.getState().userPage.userId;

const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem("token")}` } };
export const createProject = (data: IProjectCreate) => {
    return (dispatch: any) => {
        dispatch(createProjectInit());
        axios.post(`${apiUrl}/project/create`, data, header)
            .then((response) => {
                dispatch(createProjectSuccess(response.data.data));
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Internal server error',
                    text: error.response.data.data
                });
                dispatch(createProjectFailure());
            });
    };
};

export const updateProject = (data: IProjectCreate) => {
    return (dispatch: any) => {
        dispatch(updateProjectInit());
        axios.put(`${apiUrl}/project/update`, data, header)
            .then((response) => {
                dispatch(updateProjectSuccess(response.data));
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'warning',
                    text: error.response.data.data
                });
                dispatch(updateProjectFailure());
            })
    };
};

export const deactivateProject = (data: IProjectCreate) => {
    return (dispatch: any) => {
        dispatch(updateProjectInit());
        axios.delete(`${apiUrl}/project/deactivate-project?id=${data.id}`, header)
            .then((response) => {
                dispatch(updateProjectSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateProjectFailure());
            });
    };
};

export const getProjects = () => {
    return (dispatch: any) => {
        dispatch(getProjectsInit());
        axios.get(`${apiUrl}/project/all-projects`, header)
        .then((response) => {
            if(response.data.status == 200){
                dispatch(getProjectsSuccess(response.data.data));
            }
        })
        .catch(function (error) {
            dispatch(getProjectsFailure());
        });
    };
};

export const getProject = (id: any) => {
    return (dispatch: any) => {
        dispatch(getProjectInit());
        axios.get(`${apiUrl}/project/get-project?id=${id}`, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectSuccess(response.data.data));
                }
            })
            .catch(function (error) {
                dispatch(getProjectFailure());
            });
    };
};

export const createProjectInit = () => ({
    type: actionTypes.CREATE_PROJECT
});

export const createProjectFailure = () => ({
    type: actionTypes.CREATE_PROJECT_FAILURE
});

export const createProjectSuccess = (data: any) => ({
    type: actionTypes.CREATE_PROJECT_SUCCESS,
    payload: { data }
});

export const updateProjectInit = () => ({
    type: actionTypes.UPDATE_PROJECT
});

export const updateProjectFailure = () => ({
    type: actionTypes.UPDATE_PROJECT_FAILURE
});

export const updateProjectSuccess = (data: any) => ({
    type: actionTypes.UPDATE_PROJECT_SUCCESS,
    payload: { data }
});

export const getProjectsInit = () => ({
    type: actionTypes.GET_PROJECTS
});

export const getProjectsFailure = () => ({
    type: actionTypes.GET_PROJECTS_FAILURE
});

export const getProjectsSuccess = (data: any) => ({
    type: actionTypes.GET_PROJECTS_SUCCESS,
    payload: { data }
});

export const getProjectInit = () => ({
    type: actionTypes.GET_PROJECT
});

export const getProjectFailure = () => ({
    type: actionTypes.GET_PROJECT_FAILURE
});

export const getProjectSuccess = (data: any) => ({
    type: actionTypes.GET_PROJECT_SUCCESS,
    payload: { data }
});

export const getProjectDetailsInit = () => ({
    type: actionTypes.GET_PROJECT_DETAILS
});

export const getProjectDetailsFailure = () => ({
    type: actionTypes.GET_PROJECT_DETAILS_FAILURE
});

export const getProjectDetailsSuccess = (data: any) => ({
    type: actionTypes.GET_PROJECT_DETAILS_SUCCESS,
    payload: { data }
});

export const getProjectDetails = (id: StringConstructor) => {
    return (dispatch: any) => {
        dispatch(getProjectDetailsInit());
        axios.get(`${apiUrl}/project/get-project?id=${id}`, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectDetailsSuccess(response.data.data));
                }
            })
            .catch(function (error) {
                dispatch(getProjectDetailsFailure());
            })
    };
};