import { ProjectPage } from './project.component';
import { connect } from 'react-redux';
import { getProjects, getProject, createProject, updateProject, deactivateProject } from './../../api/project/projectAction';

const mapDispatchToProps = {
    getProjects,
    getProject,
    createProject,
    updateProject,
    deactivateProject,
};

const mapStateToProps = (state: any) => ({
    error: state.projectPage.isError,
    isLoading: state.projectPage.isLoading,
    projects: state.projectPage.projects,
    isSaved: state.projectPage.isSaved,
    isExists: state.projectPage.isExists,
    isError: state.projectPage.isError,
    role: state.userPage.role
});

export const ProjectContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
