import * as actionType from "./projectActionType";

export interface ProjectReducerState {
    isLoading: boolean;
    isError: boolean;
    projects: [];
    projectDetails: any | undefined;
    isSaved: boolean;
    isExists: boolean;
}

const initialState: ProjectReducerState = {
  isError: false,
  isLoading: false,
  projects: [],
  projectDetails: undefined,
  isSaved: false,
  isExists: false,
};

export const projectReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.CREATE_PROJECT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.CREATE_PROJECT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true,
            };
        case actionType.UPDATE_PROJECT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.UPDATE_PROJECT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true,
            };
        case actionType.GET_PROJECTS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_PROJECTS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                projects: action.payload ? action.payload.data : [],
            };
        case actionType.GET_PROJECT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isExists: false,
                project: {}
            };
        case actionType.GET_PROJECT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isExists: false,
            };
        case actionType.GET_PROJECT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isExists: true,
                project: action.payload ? action.payload.data : {},
            };
        case actionType.GET_PROJECT_DETAILS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_PROJECT_DETAILS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_PROJECT_DETAILS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                projectDetails: action.payload ? action.payload.data : {}
            };
        default:
            return state
    }
}
