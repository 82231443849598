import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { UserManagementTable } from './component/usermngt-table';
import { UserManagementDialog } from './component/usermngt-dialoge';
import { UsermngtDeleteDialog } from './component/usermngt-delete-dialoge';
import AddIcon from '@mui/icons-material/Add';
import Loading from './../../shared/Loading';
import { IUsermngtCreate, emptyUsermngt } from '../../model/usermngt';
import { Typography } from '@mui/material';
import { AppBreadcrumbContainer } from '../../shared/app-breadcrumb/app-breadcrumb.container';
import { startWithANumberValidator, emailValidator, specialSymbolsValidator, numberWithSpaceValidator, nameValidation } from '../../utitlities/FormValidator';
import { roles } from '../../utitlities/roles';
export interface ExternalActionProps {
    getUsers: () => void;
    createUser: (x: IUsermngtCreate) => void;
    updateUser: (x: IUsermngtCreate) => void;
    clearErrors: () => void;
}

export interface ExternalProps {
    users: any;
    errors: string[];
    isLoading: boolean;
    isSaved: boolean;
    role: string;
}

const EmptyError = {
    muid: {
        isError: false,
        text: "",
    },
    name: {
        isError: false,
        text: "",
    },
    lastName: {
        isError: false,
        text: "",
    },
    email: {
        isError: false,
        text: "",
    },
    role: {
        isError: false,
        text: "",
    },
    remarks: {
        isError: false,
        text: "",
    }
}

export function UsermanagementPage(props: ExternalActionProps & ExternalProps) {
    const { getUsers, isLoading, users, createUser, updateUser, isSaved, errors, clearErrors, role } = props;
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(emptyUsermngt);
    const [error, setError] = useState<any>(EmptyError);

    const onClose = () => {
        setSelected(emptyUsermngt);
        setOpen(false);
        setError(EmptyError);
    }

    const onCloseDelete = () => {
        setSelected(emptyUsermngt);
        setOpenDelete(false);
        setError(EmptyError);
    }

    useEffect(() => {
        getUsers();
        return () => {
            clearErrors();
        }
    }, []);

    useEffect(() => {
        if (isSaved && !isLoading) {
            getUsers();
            onClose();
            onCloseDelete();
        }
    }, [isSaved]);

    const validate = (data: IUsermngtCreate) => {
        let isInvalid = false;
        let errorNew: any = {};
        if (data.muid.trim().length === 0) {
            errorNew.muid = {
                isError: true,
                text: "MUID is mandatory"
            };

            isInvalid = true;
        }
        else if (data.muid.trim().length < 3 || data.muid.trim().length > 10) {
            errorNew.muid = {
                isError: true,
                text: "MUID must contain minimum 3 character in length & maximum 10 characters allowed"
            };

            isInvalid = true;
        }
        // else if (specialSymbolsValidator(data.muid)) {
        //     errorNew.muid = {
        //         isError: true,
        //         text: "Invalid MU Id, Only Alphanumeric and space are allowed."
        //     };

        //     isInvalid = true;
        // }    
        if (data.firstName.trim().length === 0) {
            errorNew.name = {
                isError: true,
                text: "First Name is mandatory"
            };

            isInvalid = true;
        } else if (data.firstName.trim().length < 3 || data.firstName.trim().length > 50) {
            errorNew.name = {
                isError: true,
                text: "First Name must contain minimum 3 characters in length & maximum 50 characters allowed"
            };

            isInvalid = true;
        }
        // else if (nameValidation(data.firstName)) {
        //     errorNew.name = {
        //         isError: true,
        //         text: "Invalid first name, Only Alphanumeric and space are allowed."
        //     };

        //     isInvalid = true;
        // }
        // else if (numberWithSpaceValidator(data.firstName)) {
        //     errorNew.name = {
        //         isError: true,
        //         text: "First Name cannot be a number"
        //     };

        //     isInvalid = true;
        // }
        // else if (startWithANumberValidator(data.firstName)) {
        //     errorNew.name = {
        //         isError: true,
        //         text: "First Name cannot start with a number"
        //     };

        //     isInvalid = true;
        // }
        if (data.lastName.trim().length === 0) {
            errorNew.lastName = {
                isError: true,
                text: "Last Name is mandatory"
            };

            isInvalid = true;
        } else if (data.lastName.trim().length < 3 || data.lastName.trim().length > 50) {
            errorNew.lastName = {
                isError: true,
                text: "Last Name must contain minimum 3 characters in length & maximum 50 characters allowed"
            };

            isInvalid = true;
        }
        // else if (nameValidation(data.lastName)) {
        //     errorNew.lastName = {
        //         isError: true,
        //         text: "Invalid last name, Only Alphanumeric and space are allowed."
        //     };

        //     isInvalid = true;
        // }
        // else if (numberWithSpaceValidator(data.lastName)) {
        //     errorNew.lastName = {
        //         isError: true,
        //         text: "Last Name cannot be a number"
        //     };

        //     isInvalid = true;
        // }
        // else if (startWithANumberValidator(data.lastName)) {
        //     errorNew.lastName = {
        //         isError: true,
        //         text: "Last Name cannot start with a number"
        //     };

        //     isInvalid = true;
        // }

        if (data.email.trim().length === 0) {
            errorNew.email = {
                isError: true,
                text: "Email Id is mandatory"
            };

            isInvalid = true;
        }
        else if (!emailValidator(data.email)) {
            errorNew.email = {
                isError: true,
                text: "You have entered an invalid email address!"
            };

            isInvalid = true;
        }
        else if (data.email.trim().length < 5 || data.email.trim().length > 100) {
            errorNew.email = {
                isError: true,
                text: "Email must contain minimum 5 characters in length & maximum 100 characters allowed"
            };

            isInvalid = true;
        }
        if (data.role === "") {
            errorNew.role = {
                isError: true,
                text: "Role is mandatory"
            };
            isInvalid = true;
        }
        if (data.id && typeof data.remarks === 'string' && data.remarks.trim().length === 0) {
            errorNew.remarks = {
                isError: true,
                text: "Remarks is mandatory"
            };

            isInvalid = true;
        } else if (data.id && ((data?.remarks && data.remarks.trim().length < 10) || (data?.remarks && data.remarks.trim().length > 200))) {
            errorNew.remarks = {
                isError: true,
                text: "Remarks must contain minimum 10 characters in length & maximun 200 characters allowed"
            };

            isInvalid = true;
        }
        if (!isInvalid) {
            if (data.id) {
                updateUser(data)
            } else {
                createUser(data)
            }
        } else {
            setError(errorNew);
        }
    }

    useEffect(() => {
        if (errors && errors.length > 0) {
            if (errors.indexOf('ID_EXISTS') > -1) {
                setError({
                    muid: {
                        isError: true,
                        text: "MUID already exists",
                    }
                })
                return
            } else {
                setError(Object.assign(error, {
                    muid: {
                        isError: false,
                        text: "",
                    }
                }))
            }
            if (errors.indexOf('EMAIL_EXISTS') > -1) {
                setError({
                    email: {
                        isError: true,
                        text: "Email already exists",
                    }
                })
                return
            } else {
                setError(Object.assign(error, {
                    email: {
                        isError: false,
                        text: "",
                    }
                }))
            }
        }
    }, [errors]);


    return (
        <React.Fragment>
            <Loading loading={isLoading} />
            {role == roles.SUPER_ADMIN && (
                <AppBreadcrumbContainer breadcrumbs={[]} currentItem={'User Administration'} isUploading={false}></AppBreadcrumbContainer>
            )}
            <div style={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography variant="h6">Users List</Typography>
                <Button  style={{background: '#084056' }} className='button-text' variant="contained" color="primary" onClick={() => setOpen(true)}>
                    <AddIcon /> Add User
                </Button>
            </div>

            <UserManagementTable
                users={users}
                setSelected={setSelected}
                setOpen={() => setOpen(true)}
                setOpenDelete={setOpenDelete}
            />
            {open &&
                <UserManagementDialog
                    open={open}
                    onClose={onClose}
                    selected={selected}
                    onSave={validate}
                    setSelected={setSelected}
                    error={error}
                    setError={setError}
                />
            }
            {openDelete &&
                <UsermngtDeleteDialog
                    open={openDelete}
                    onClose={onCloseDelete}
                    selected={selected}
                    deactivateUser={validate}
                    error={error}
                    setError={setError}
                    setSelected={setSelected}
                />
            }

        </React.Fragment>
    );
}