export interface IProjectCreate {
    id?: number;
    projCode: string;
    projName: string;
    projDescription: string;
    remarks?: string;
    isActive?: boolean
}

export const emptyProject: IProjectCreate = {
    projCode: '',
    projName: '',
    projDescription: '',
    remarks: '',
    isActive: true,
};
