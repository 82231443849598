import * as actionTypes from "./auditActionType";
import axios from 'axios';
import store from '../../app/store';

const apiUrl = process.env.REACT_APP_API_URL;
const userId = store.getState().userPage.userId;

const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem("token")}` } };
export const getAuditInit = () => ({
    type: actionTypes.GET_AUDIT
});

export const getAuditSuccess = (data: any) => ({
    type: actionTypes.GET_AUDIT_SUCCESS,
    payload: { data }
});

export const getAuditFailure = () => ({
    type: actionTypes.GET_AUDIT_FAILURE
});

export const getAuditTrails = () => {
    return (dispatch: any) => {
        dispatch(getAuditInit());
        axios.get(`${apiUrl}/history/audit-trails`, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getAuditSuccess(response.data.data));
                }
            })
            .catch(function (error) {
                dispatch(getAuditFailure());
            });
    };
};