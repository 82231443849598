export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

export const GET_SSO_TOKEN = "GET_SSO_TOKEN";
export const GET_SSO_TOKEN_FAILURE = "GET_SSO_TOKEN_FAILURE";
export const GET_SSO_TOKEN_SUCCESS = "GET_SSO_TOKEN_SUCCESS";

export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const GET_USER_DETAIL_FAILURE = "GET_USER_DETAIL_FAILURE";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";