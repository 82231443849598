import * as actionTypes from "./studyActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { IStudyCreate } from "../../model/study";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import store from '../../app/store';
import { status } from "../../utitlities/StudyStatus";
import Swal from 'sweetalert2';
import { Client } from "stompjs";

const apiUrl = process.env.REACT_APP_API_URL;

const userId = store.getState().userPage.userId;
const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem("token")}` } };

export const createStudy = (data: IStudyCreate, stompClient: Client | null) => {
    let dataObj = {
        "microscopeSeriesId": [
            data.microscopeSeries
        ],
        "projectId": data.projectId,
        "remarks": data.remarks,
        "studDescription": data.studDescription,
        "studName": data.studName
    };
    return (dispatch: any) => {
        dispatch(updateUploadCounter(++store.getState().studyPage.uploadCounter));
        dispatch(createStudyInit());
        axios.post(`${apiUrl}/study/create`,
            dataObj,
            header)
            .then((response) => {
                dispatch(createStudySuccess(response.data.data));
                setTimeout(() => {
                    uploadFiles(data.files, response.data.data, stompClient)
                }, 500);
            })
            .catch(function (error) {
                dispatch(updateUploadCounter(--store.getState().studyPage.uploadCounter));
                if (error.response) {
                    if (error.response.status === 400) {
                        Swal.fire({
                            icon: 'error',
                            // title: 'Study Name should not have special characters.',
                            text: error.response.data.data
                        });
                    } else if (error.response.status === 500) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Internal server error',
                            text: error.response.data.data
                        });
                    }
                }
                dispatch(createStudyFailure());
            })
    };
};

export const uploadFiles = async (files: any[], studyDetails: any, stompClient: Client | null) => {
    if (stompClient) {
        stompClient.send('/app/receive', undefined, JSON.stringify({ "topic": "UPLOAD", "subTopic": "UPLOAD_IN_PROGRESS", "data": { "studyCode": studyDetails.studCode, "user": userId } }));

        const credentials = {
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
        };
        let filesUploaded = 0;

        sendLogMessage({
            log: `Upload initiated for study with code ${studyDetails.studCode}`
        }, stompClient);

        const studies = store.getState().studyPage.study;
        let study = studies.find((study: any) => study.studCode === studyDetails.studCode);
        updateProjectStudies({ ...study, progress: 0 });
        for await (const file of files) {
            try {
                await uploadFile(file, credentials, studyDetails);
                filesUploaded++;

                sendLogMessage({
                    log: `${file.name} uploaded successfully for the study with code ${studyDetails.studCode}`
                }, stompClient);
                sendLogMessage({
                    log: `${filesUploaded} of ${files.length} are uploaded for the study with code ${studyDetails.studCode}`
                }, stompClient);

                if (study) {
                    study.progress = (filesUploaded * 100) / files.length;
                    study.status = status.UPLOAD_IN_PROGRESS;
                    if (filesUploaded === files.length) {
                        study.progress = 100;
                        study.status = "Uploaded";

                        sendLogMessage({
                            log: `Study with code ${studyDetails.studCode} uploaded successfully`
                        }, stompClient);
                        stompClient.send('/app/receive', undefined, JSON.stringify({ "topic": "UPLOAD", "subTopic": "UPLOAD_SUCCESSFUL", "data": { "studyCode": studyDetails.studCode, "user": userId } }));
                        store.dispatch(updateUploadCounter(--store.getState().studyPage.uploadCounter));
                    }
                    updateProjectStudies(study);
                }
            }
            catch (e) {
                sendLogMessage({
                    log: `Study with code ${studyDetails.studCode} upload failed`
                }, stompClient);
                if (stompClient) {
                    stompClient.send('/app/receive', undefined, JSON.stringify({
                        "topic": "UPLOAD", "subTopic": "UPLOAD_FAILED", "data": {
                            studyCode: studyDetails.studCode,
                            user: userId
                        }
                    }));
                }
                store.dispatch(updateUploadCounter(--store.getState().studyPage.uploadCounter));
                break;
            }
        }
    } else {
        uploadFiles(files, studyDetails, stompClient);
    }
}

function updateProjectStudies(study: IStudyCreate) {
    const studies = store.getState().studyPage.study.map((studyInd: IStudyCreate) => {
        if (studyInd.studCode !== study.studCode) {
            return studyInd;
        }

        return study;
    });
    store.dispatch(getByProjectSuccess([...studies]));
}

function sendLogMessage(message: { log: string }, stompClient: Client | null) {
    if (stompClient) {
        stompClient.send('/app/receive', undefined, JSON.stringify({ "topic": "UPLOAD", "subTopic": "LOG_RECORDER", "data": message }));
    }
}

async function uploadFile(file: any, credentials: any, studyDetails: any) {
    let return_data = { error: 0, message: '' };
    try {
        const target = { Bucket: process.env.REACT_APP_BUCKET, Key: `${studyDetails.studCode}/raw_data/${file.name}`, Body: file };
        const parallelUploads3 = new Upload({
            client: new S3Client({ region: process.env.REACT_APP_REGION, credentials }),
            leavePartsOnError: false,
            params: target,
        });

        await parallelUploads3.done();
    }
    catch (e: any) {
        return_data = { error: 1, message: e.message };
    }

    return return_data;
}

export const fetchProcessedStudy = (data: any) => {
    const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
    return (dispatch: any) => {
        dispatch(fetchProcessedStudyInit());
        axios.get(`${apiUrl}/data/get-processed-data`, header)
            .then((response) => {
                dispatch(fetchProcessedStudySuccess(response.data.data));
            })
            .catch(function (error) {
                dispatch(fetchProcessedStudyFailure());
            })
    };
};

export const updateStudy = (data: IStudyCreate, stompClient: Client | null) => {
    let dataObj = {
        "id": data.id,
        "microscopeSeriesId": [
            data.microscopeSeries
        ],
        "isActive": data.isActive,
        "projectId": data.projectId,
        "remarks": data.remarks,
        "studDescription": data.studDescription,
        "studName": data.studName
    };
    return (dispatch: any) => {
        dispatch(updateStudyInit());
        axios.put(`${apiUrl}/study/update`, dataObj, header)
            .then((response) => {
                dispatch(updateStudySuccess(response.data));
                console.log("Files: ", data);
                if (data.files.length > 0) {
                    console.log("Inside: ", data.files);
                    dispatch(updateUploadCounter(++store.getState().studyPage.uploadCounter));
                    setTimeout(() => {
                        uploadFiles(data.files, response.data.data, stompClient)
                    }, 500);
                }
            })
            .catch(function (error) {
                dispatch(updateStudyFailure());
            })
    };
};

export const updateWell = (data: any) => {
    return (dispatch: any) => {
        dispatch(updateWellInit());
        axios.put(`${apiUrl}/data/update-result`, data, header)
            .then((response) => {
                dispatch(updateWellSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateWellFailure());
            })
    };
};

export const commitStudy = (data: any) => {
    return (dispatch: any) => {
        dispatch(commitStudyInit());
        axios.put(`${apiUrl}/data/commit-study`, data, header)
            .then((response) => {
                dispatch(commitStudySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(commitStudyFailure());
            })
    };
};

export const downloadStudy = (data: any) => {
    const fileName = `${data.studyCode}_${data.plateName}.zip`;
    const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem('token')}` }, params: data, responseType: 'blob' as 'blob' };
    return (dispatch: any) => {
        axios.get(`${apiUrl}/data/download-processed-results`, header)
            .then((response) => {
                const { data, headers } = response;
                const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
            })
    };
};

export const downloadWholeStudy = (data: any) => {
    const studyCode = data.studyCode;
    const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem('token')}` }, params: data, responseType: 'blob' as 'blob' };
    return (dispatch: any) => {
        axios.get(`${apiUrl}/data/download-results-by-study`, header)
            .then((response) => {
                const { data, headers } = response;
                const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${studyCode}.zip`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
            })
    };
};

export const deactivateStudy = (data: IStudyCreate) => {
    return (dispatch: any) => {
        dispatch(updateDeactivateStudyInit());
        axios.delete(`${apiUrl}/study/deactivate-study?id=${data.id}`, header)
            .then((response) => {
                dispatch(updateDeactivateStudySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateDeactivateStudyFailure());
            })
    };
};

export const getStudies = () => {
    return (dispatch: any) => {
        dispatch(getStudiesInit());
        axios.get(`${apiUrl}/study/all-studies`, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getStudiesSuccess(response.data.data));
                }
            })
            .catch(function (error) {
                dispatch(getStudiesFailure());
            })
    };
};

export const getByProject = (id: StringConstructor) => {
    return (dispatch: any) => {
        dispatch(getByProjectInit());
        axios.get(`${apiUrl}/study/get-by-project?projectId=${id}`, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getByProjectSuccess(response.data.data));
                }
            })
            .catch(function (error) {
                dispatch(getByProjectFailure());
            })
    };
};

export const getStudy = (id: StringConstructor, callFetchProcessedStudy = false) => {
    return (dispatch: any) => {
        dispatch(getStudyInit());
        axios.get(`${apiUrl}/study/get-study?id=${id}`, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getStudySuccess(response.data.data));
                    if (callFetchProcessedStudy) {
                        dispatch(fetchProcessedStudy({ studyCode: response.data.data.studCode }))
                    }
                }
            })
            .catch(function (error) {
                dispatch(getStudyFailure());
            })
    };
};

export const getSignedUrl = (id: string, key: string) => {
    return (dispatch: any) => {
        dispatch(getSignedUrlInit());
        let requestBody = {
            fileName: key,
            studyCode: id
        };

        axios.post(`${apiUrl}/data/get-s3-presigned-url`, requestBody, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getSignedUrlSuccess(response.data.data));
                }
            })
            .catch(function (error) {
                dispatch(getSignedUrlFailure());
            })
    };
};

export const updateStatusBySocket = (studCode: any, studyStatus: string, message: string, stompClient: Client) => {
    return (dispatch: any) => {
        const studies = store.getState().studyPage.study;
        let study = studies.find((study: any) => study.studCode === studCode);

        if (study) {
            study.status = studyStatus;
            study.statusMessage = message;
            updateProjectStudies(study);
        }

        if (studyStatus === status.UPLOAD_SUCCESSFUL) {
            stompClient.send('/app/receive', undefined, JSON.stringify({ "topic": "UPLOAD", "subTopic": "INVOKE_PROCESSING", "data": { "studyCode": studCode, "user": userId } }));
        }
    };
};

export const createStudyInit = () => ({
    type: actionTypes.CREATE_STUDY
});

export const createStudyFailure = () => ({
    type: actionTypes.CREATE_STUDY_FAILURE
});

export const createStudySuccess = (data: any) => ({
    type: actionTypes.CREATE_STUDY_SUCCESS,
    payload: { data }
});

export const getStudyInit = () => ({
    type: actionTypes.GET_STUDY
});

export const getStudyFailure = () => ({
    type: actionTypes.GET_STUDY_FAILURE
});

export const getStudySuccess = (data: any) => ({
    type: actionTypes.GET_STUDY_SUCCESS,
    payload: { data }
});

export const getSignedUrlInit = () => ({
    type: actionTypes.GET_SIGNED_URL
});

export const getSignedUrlFailure = () => ({
    type: actionTypes.GET_SIGNED_URL_FAILURE
});

export const getSignedUrlSuccess = (data: any) => ({
    type: actionTypes.GET_SIGNED_URL_SUCCESS,
    payload: { data }
});

export const downloadStudyInit = () => ({
    type: actionTypes.DOWNLOAD_STUDY
});

export const downloadStudyFailure = () => ({
    type: actionTypes.DOWNLOAD_STUDY_FAILURE
});

export const downloadStudySuccess = (data: any) => ({
    type: actionTypes.DOWNLOAD_STUDY_SUCCESS,
    payload: { data }
});

export const fetchProcessedStudyInit = () => ({
    type: actionTypes.PROCESSED_STUDY
});

export const fetchProcessedStudyFailure = () => ({
    type: actionTypes.PROCESSED_STUDY_FAILURE
});

export const fetchProcessedStudySuccess = (data: any) => ({
    type: actionTypes.PROCESSED_STUDY_SUCCESS,
    payload: { data }
});

export const updateStudyInit = () => ({
    type: actionTypes.UPDATE_STUDY
});

export const updateStudyFailure = () => ({
    type: actionTypes.UPDATE_STUDY_FAILURE
});

export const updateStudySuccess = (data: any) => ({
    type: actionTypes.UPDATE_STUDY_SUCCESS,
    payload: { data }
});

export const updateDeactivateStudyInit = () => ({
    type: actionTypes.UPDATE_DEACTIVATE_STUDY
});

export const updateDeactivateStudyFailure = () => ({
    type: actionTypes.UPDATE_DEACTIVATE_STUDY_FAILURE
});

export const updateDeactivateStudySuccess = (data: any) => ({
    type: actionTypes.UPDATE_DEACTIVATE_STUDY_SUCCESS,
    payload: { data }
});

export const updateWellInit = () => ({
    type: actionTypes.UPDATE_WELL
});

export const updateWellFailure = () => ({
    type: actionTypes.UPDATE_WELL_FAILURE
});

export const updateWellSuccess = (data: any) => ({
    type: actionTypes.UPDATE_WELL_SUCCESS,
    payload: { data }
});

export const commitStudyInit = () => ({
    type: actionTypes.COMMIT_STUDY
});

export const commitStudyFailure = () => ({
    type: actionTypes.COMMIT_STUDY_FAILURE
});

export const commitStudySuccess = (data: any) => ({
    type: actionTypes.COMMIT_STUDY_SUCCESS,
    payload: { data }
});

export const getStudiesInit = () => ({
    type: actionTypes.GET_STUDIES
});

export const getStudiesFailure = () => ({
    type: actionTypes.GET_STUDIES_FAILURE
});

export const getStudiesSuccess = (data: any) => ({
    type: actionTypes.GET_STUDIES_SUCCESS,
    payload: { data }
});

export const getByProjectInit = () => ({
    type: actionTypes.GET_BY_PROJECT
});

export const getByProjectFailure = () => ({
    type: actionTypes.GET_BY_PROJECT_FAILURE
});

export const getByProjectSuccess = (data: any) => ({
    type: actionTypes.GET_BY_PROJECT_SUCCESS,
    payload: { data }
});

export const updateUploadCounter = (data: any) => ({
    type: actionTypes.UPDATE_UPLOAD_COUNTER,
    payload: { data }
});