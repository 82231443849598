import * as actionTypes from "./masterActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../../app/store';

const apiUrl = process.env.REACT_APP_API_URL;
const userId = store.getState().userPage.userId;
const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem("token")}` } };

export const getTechniques = () => {
    return (dispatch: any) => {
        dispatch(getTechniquesInit());
        axios.get(`${apiUrl}/technique/all-techniques`, header)
        .then((response) => {
            if(response.data.status == 200){
                dispatch(getTechniquesSuccess(response.data.data));
            }
        })
        .catch(function (error) {
        dispatch(getTechniquesFailure());
    })
    };
};

export const getCqas = () => {
    return (dispatch: any) => {
        dispatch(getCqasInit());
        axios.get(`${apiUrl}/cqa/all-cqa`, header)
        .then((response) => {
            if(response.data.status == 200){
                dispatch(getCqasSuccess(response.data.data));
            }
        })
        .catch(function (error) {
        dispatch(getCqasFailure());
    })
    };
};

export const getMolecules = () => {
    return (dispatch: any) => {
        dispatch(getMoleculesInit());
        axios.get(`${apiUrl}/molecules/all-molecules`, header)
        .then((response) => {
            if(response.data.status == 200){
                dispatch(getMoleculesSuccess(response.data.data));
            }
        })
        .catch(function (error) {
        dispatch(getMoleculesFailure());
    })
    };
};

export const getTechniquesInit = () => ({
    type: actionTypes.GET_TECHNIQUES
});

export const getTechniquesFailure = () => ({
    type: actionTypes.GET_TECHNIQUES_FAILURE
});

export const getTechniquesSuccess = (data: any) => ({
    type: actionTypes.GET_TECHNIQUES_SUCCESS,
    payload: { data }
});

export const getCqasInit = () => ({
    type: actionTypes.GET_CQAS
});

export const getCqasFailure = () => ({
    type: actionTypes.GET_CQAS_FAILURE
});

export const getCqasSuccess = (data: any) => ({
    type: actionTypes.GET_CQAS_SUCCESS,
    payload: { data }
});

export const getMoleculesInit = () => ({
    type: actionTypes.GET_MOLECULES
});

export const getMoleculesFailure = () => ({
    type: actionTypes.GET_MOLECULES_FAILURE
});

export const getMoleculesSuccess = (data: any) => ({
    type: actionTypes.GET_MOLECULES_SUCCESS,
    payload: { data }
});

export const getMicroscopeSeriesInit = () => ({
    type: actionTypes.GET_MICROSCOPE_SERIES
});

export const getMicroscopeSeriesFailure = () => ({
    type: actionTypes.GET_MICROSCOPE_SERIES_FAILURE
});

export const getMicroscopeSeriesSuccess = (data: any) => ({
    type: actionTypes.GET_MICROSCOPE_SERIES_SUCCESS,
    payload: { data }
});

export const getMicroscopeSeries = () => {
    return (dispatch: any) => {
        dispatch(getMicroscopeSeriesInit());
        axios.get(`${apiUrl}/microscope-series/all-microscopeseries`, header)
        .then((response) => {
            if(response.data.status === 200){
                dispatch(getMicroscopeSeriesSuccess(response.data.data));
            }
        })
        .catch(function (error) {
        dispatch(getMicroscopeSeriesFailure());
    })
    };
};