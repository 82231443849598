import { ReactComponent as ReactLogo } from '../../tibil.svg';
import React, { useContext } from 'react';
import { RoutePath } from './../../routes';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { roles } from '../../utitlities/roles';
import { WebSocketContext } from '../app-socket/WebSocket';
import { IStudyCreate } from '../../model/study';

export default function AppRoot(props: any) {
  const { logout, isLoggedIn, muid, userName, role, uploadCounter, study, userId } = props;

  const ws = useContext(WebSocketContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const [openList, setOpenList] = React.useState(false);

  const handleClickList = () => {
    setOpenList(!openList);
  };

  const openInNewTab = (url: any) => {
    var c = document.createElement("a");
    c.href = url;
    c.target = "_blank";
    c.click();
  };

  const callFailSocket = () => {
    study.forEach((indStudy: IStudyCreate) => {
      if (indStudy.progress !== undefined && indStudy.progress < 100) {
        ws.stompClient.send('/app/receive', undefined, JSON.stringify({ "topic": "UPLOAD", "subTopic": "UPLOAD_FAILED", "data": { "studyCode": indStudy.studCode, "user": userId } }));
      }
    });
  };

  const confirmationMessage = "You have files to be upload. Continue?";

  const useConfirmTabClose = (isUnsafeTabClose: boolean) => {
    React.useEffect(() => {
      const handleBeforeUnload = (event: any) => {
        if (isUnsafeTabClose) {
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        }
      };
      
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [isUnsafeTabClose]);
  };

  const useTabClose = (isUnsafeTabClose: boolean) => {
    React.useEffect(() => {
      const handleUnload = (event: any) => {
        if (isUnsafeTabClose) {
          callFailSocket();
        }
      };
      
      window.addEventListener("unload", handleUnload);
      return () =>
        window.removeEventListener("unload", handleUnload);
    }, [isUnsafeTabClose]);
  };

  useConfirmTabClose(uploadCounter > 0);
  useTabClose(uploadCounter > 0);

  return (
    <React.Fragment>
      {isLoggedIn &&
        <AppBar position="static">
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', background: '#084056'  }}>
            <Typography   className="logo-container" variant="h6" component="div">
              <a target="_blank" href='https://tibilsolutions.com/'><ReactLogo className="app-logo" /></a>
            </Typography>
            <Typography variant="h6">Viral Clearance</Typography>
            <Tooltip title="Account settings">
              <Button
                color="inherit"
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <PersonIcon />&nbsp;
              </Button>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <List
                sx={{ width: '100%', minWidth: '250px' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {userName}({muid})
                  </ListSubheader>
                }
              >
                {(role == roles.TIBIL_IT) || (role == roles.SUPER_ADMIN) ?
                  <>
                    <ListItemButton onClick={() => handleClickList()} >
                      <ListItemText primary="Administration" />
                      {openList ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding onClick={handleClose}>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/user-administration')}>
                          <ListItemText primary="User Administration" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/admin-logs')}>
                          <ListItemText primary="Admin Logs" />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </>
                  : ''}
                <ListItemButton onClick={() => navigate('/audit-trail')}>
                  <ListItemText primary="Audit Trails" onClick={handleClose} />
                </ListItemButton>
                <ListItemButton onClick={onLogout}>
                  <ListItemText primary="Log Out" />
                </ListItemButton>
              </List>
            </Menu>
          </Toolbar>
        </AppBar>
      }
      <div style={{ padding: '20px 40px' }}>
        <RoutePath />
      </div>
    </React.Fragment >
  );
}