import { FormatListNumberedRtl } from "@mui/icons-material";
import * as actionType from "./studyActionType";

export interface StudyReducerState {
    isLoading: boolean;
    isError: boolean;
    studies: [];
    isSaved: boolean;
    isExists: boolean;
    study: any;
    processedStudy: any;
    fetched: boolean;
    created: any;
    uploadCounter: any;
    wellUpdated: boolean;
    singleStudy: any;
    signedUrl: any;
}

const initialState: StudyReducerState = {
  isError: false,
  isLoading: false,
  studies: [],
  isSaved: false,
  isExists: false,
  study: [],
  processedStudy: [],
  fetched: false,
  created: {},
  uploadCounter: 0,
  wellUpdated: false,
  singleStudy: {},
  signedUrl: ""
};

export const studyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.UPDATE_UPLOAD_COUNTER:
            return {
                ...state,
                uploadCounter: action.payload.data
            };
        case actionType.CREATE_STUDY:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.CREATE_STUDY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_STUDY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                study: [action.payload.data].concat(state.study),
                created: action.payload ? action.payload.data : {}
            };
        case actionType.GET_STUDY:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_STUDY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_STUDY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                singleStudy: action.payload ? action.payload.data : {}
            };
        case actionType.GET_SIGNED_URL:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_SIGNED_URL_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_SIGNED_URL_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                signedUrl: action.payload ? action.payload.data : ""
            };
        case actionType.PROCESSED_STUDY:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.PROCESSED_STUDY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.PROCESSED_STUDY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                processedStudy: action.payload ? action.payload.data : []
            };
        case actionType.UPDATE_STUDY:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.UPDATE_STUDY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_STUDY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                study: state.study.map((x: any) => {
                    if (action.payload.data && x.id == action.payload.data.data.id) {
                        return action.payload.data.data;
                    }
                    return x;
                })
            };
        case actionType.UPDATE_DEACTIVATE_STUDY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_DEACTIVATE_STUDY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.UPDATE_DEACTIVATE_STUDY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_WELL:
            return {
                ...state,
                isError: false,
                isLoading: true,
                wellUpdated: false,
            };
        case actionType.UPDATE_WELL_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                wellUpdated: false,
            };
        case actionType.UPDATE_WELL_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                wellUpdated: true,
            };
        case actionType.COMMIT_STUDY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                wellUpdated: false,
                isStudyComitted: false,
                isSaved: false
            };
        case actionType.COMMIT_STUDY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                wellUpdated: false,
                isStudyComitted: false,
                isSaved: false
            };
        case actionType.COMMIT_STUDY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                wellUpdated: true,
                isStudyComitted: true,
                isSaved: true
            };
        case actionType.COMMIT_BACK_STUDY:
            return {
                ...state,
                isStudyComitted: action.payload.data.data,
            };
        case actionType.GET_STUDIES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_STUDIES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_STUDIES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                studies: action.payload ? action.payload.data : [],
            };
        case actionType.GET_BY_PROJECT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                fetched: false,
            };
        case actionType.GET_BY_PROJECT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                fetched: false,
            };
        case actionType.GET_BY_PROJECT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                study: action.payload ? action.payload.data : {},
                fetched: true,
            };
        default:
            return state
    }
}
