import { createContext } from 'react'
import SockJS from 'sockjs-client';
import { over, Client } from 'stompjs';
import { status } from './../../utitlities/StudyStatus';

const websocketUrl = process.env.REACT_APP_WEB_SOCKET_URL || "";
interface IParsedData {
    topic: string;
    subTopic: string;
    data?: any
}

interface IContextProps {
    stompClient: any;
}

const WebSocketContext = createContext({} as IContextProps);

export { WebSocketContext };

export const WebSocket = (props: any) => {
    const { children, updateStatusBySocket, isLoggedIn } = props;
    let stompClient: Client | null = null;

    if (isLoggedIn) {
        let socket: any = new SockJS(websocketUrl + "?access_token=" + localStorage.getItem("token"));
        stompClient = over(socket);
        console.log("Connection: ", stompClient);
        
        if (stompClient) {
            stompClient.debug = () => {};

            stompClient.connect({ }, function (frame) {
        
                stompClient?.subscribe('/topic/upload', function (data) {
                    console.log("Data: ", data);
                    const parsedData: IParsedData = typeof data.body == 'string' ? JSON.parse(data.body) : null;
                    if (parsedData && parsedData.subTopic && parsedData.data && (parsedData.data.studyIdentifier || parsedData.data.studCode)) {
                        const studyStatus: string = status[parsedData.subTopic as keyof typeof status];
                        const statusMessage: string = parsedData.data.statusMessage;
                        updateStatusBySocket(parsedData.data.studyIdentifier || parsedData.data.studCode, studyStatus, statusMessage, stompClient);
                    }
                });
            });
        }
    }

    return (
        <WebSocketContext.Provider value={{ stompClient }}>
            {children}
        </WebSocketContext.Provider>
    )
}

export default WebSocket;
