import { AdminPage } from './admin.component';
import { connect } from 'react-redux';
import { getAdminTrails } from "../../api/admin/adminAction";

const mapDispatchToProps = {
    getAdminTrails
};

const mapStateToProps = (state: any) => ({
    error: state.adminPage.isError,
    isLoading: state.adminPage.isLoading,
    admins: state.adminPage.admins
});

export const AdminContainer = connect(mapStateToProps, mapDispatchToProps)(AdminPage);
