import React from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { getDateTimeAsFormat } from '../../../utitlities/Time';

const columns = [
    {
        name: "type",
        label: "Event",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "logMessage",
        label: "Description",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "createdAt",
        label: "Date and Time",
        options: {
            filter: true,
            sort: true,
        },
    },
];

const options = {
    viewColumns: false,
    print: false,
    download: true,
    downloadOptions: { filename: 'admin-logs.csv', separator: ',' },
    selectableRowsHideCheckboxes: true,
    textLabels: {
        body: {
            noMatch: 'No Records Found',
        }
    },
};

export interface ExternalProps {
    admins: any;
}

export function AdminTable(props: ExternalProps) {
    const { admins } = props;

    const data = admins.map((data: any) => ({ ...data, createdAt: getDateTimeAsFormat(data.createdAt), type: data.type, logMessage: data.logMessage }))

    const downloadPdf = () => {
        const doc = new jsPDF()
        doc.text("Admin Logs", 20, 10)
        autoTable(doc, {
            theme: 'grid',
            columns: columns.map(col => ({ ...col, header: col.label, dataKey: col.name })),
            body: data
        })
        doc.save('admin-logs.pdf')
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography variant="h6"></Typography>
                <Button style={{background: '#084056' }} className='button-text' variant="contained" color="primary" onClick={downloadPdf}>
                    Export Pdf
                </Button>
            </div>
            <MUIDataTable
                title={"Admin Logs"}
                data={data}
                columns={columns}
                options={options}
            />
        </React.Fragment>
    );
}

