export const GET_USERS = "GET_USERS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
