import * as React from 'react';
import TextField from '@mui/material/TextField';
import { descriptionValidator } from '../../utitlities/FormValidator';
import InfoIcon from '@mui/icons-material/Info';

export interface ExternalActionProps {
    clearErrors: () => void;
    onChange: (value: string) => void;
}

export interface ExternalProps {
    id: string;
    label: string;
    error: any;
    value: string | undefined;
    autoFocus?: boolean;
}

export function AppDescriptionOrRemarksField(props: ExternalActionProps & ExternalProps) {
    const { id, label, error, value, clearErrors, onChange, autoFocus } = props;
    const [showErrorInfo, setShowErrorInfo] = React.useState<boolean>(false);
    const [errorInfo, setErrorInfo] = React.useState<string>('');

    return (
        <>
            <TextField
                autoFocus={autoFocus}
                error={error && error.isError}
                multiline
                margin="dense"
                id={id}
                label={label}
                type="text"
                helperText={error && error.text}
                rows={4}
                fullWidth
                value={value}
                inputProps={{ maxLength: 200 }}
                onChange={e => {
                    if (error) {
                        clearErrors();
                    }

                    if (descriptionValidator(e.target.value)) {
                        clearErrors();
                        onChange(e.target.value);
                        setShowErrorInfo(false);
                        return;
                    }

                    if (e.target.value === ' ') {
                        setErrorInfo(`Can't start with a space`);
                        setShowErrorInfo(true);
                    } else {
                        setErrorInfo(`Only alphanumerics,space and some special characters i.e., _:;'"(),.&- are allowed`);
                        setShowErrorInfo(true);
                    }
                }}
                variant="outlined"
            />
            {(!error || !error.isError) && showErrorInfo && (
                <div className="d-flex align-items-center" style={{ color: '#d1a960', fontSize: '12px' }}>
                    <InfoIcon fontSize="small" /> {errorInfo}
                </div>
            )}
        </>
    );
}