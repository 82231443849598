export const rolePermittedRoutes = {
    TIBIL_IT: [
        'user-administration', 'admin-logs', 'audit-trail'
    ],
    USER: [
        'projects', 'study', 'audit-trail'
    ],
    EXPERT_USER: [
        'projects', 'study', 'audit-trail'
    ],
    SUPER_ADMIN: [
        'projects', 'study', 'user-administration', 'admin-logs', 'audit-trail'
    ]
}