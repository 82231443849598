import React, { useState } from 'react';
import OAuth2Login from 'react-simple-oauth2-login';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ReactLogo } from './../../tibil.svg';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://tibilsolutions.com/">
                2022 Merck & Co. Inc.,
            </Link>
        </Typography>
    );
}


const LandingPage = () => {
  const theme = createTheme();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Grid container justifyContent="center">
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    className="cardMedia"
                    component="img"
                    height="140"
                    image="/tibil.svg"
                    alt="Merck"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      AI-Chromo
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card variant="outlined" sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => navigate("/")}>
                  <CardMedia
                    className="cardMedia"
                    component="img"
                    height="140"
                    image="/tibil.svg"
                    alt="Merck"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Viral-Clearence
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={4}>
              <Card variant="outlined" sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    className="cardMedia"
                    component="img"
                    height="140"
                    image="/tibil.svg"
                    alt="Merck"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      AI-Particle
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
      </ThemeProvider>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </React.Fragment >
  );
};

export const LandingPageContainer = LandingPage;
