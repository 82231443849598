import { StudyPage } from './study.component';
import { connect } from 'react-redux';
import { getStudies, getByProject, createStudy, updateStudy, deactivateStudy } from './../../api/study/studyAction';
import { getMicroscopeSeries } from './../../api/master/masterAction';

const mapDispatchToProps = {
    getStudies,
    getByProject,
    getMicroscopeSeries,
    createStudy,
    updateStudy,
    deactivateStudy
};

const mapStateToProps = (state: any) => ({
    error: state.studyPage.isError,
    isLoading: state.studyPage.isLoading,
    isSaved: state.studyPage.isSaved,
    isExists: state.studyPage.isExists,
    study: state.studyPage.study,
    microscopeSeries: state.masterPage.microscopeSeries,
    uploadCounter: state.studyPage.uploadCounter,
    userId: state.userPage.userId
});

export const StudyContainer = connect(mapStateToProps, mapDispatchToProps)(StudyPage);
