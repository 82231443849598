import * as actionTypes from "./adminActionType";
import axios from 'axios';
import store from '../../app/store';

const apiUrl = process.env.REACT_APP_API_URL;
const userId = store.getState().userPage.userId;

const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem("token")}` } };

export const getAdminInit = () => ({
    type: actionTypes.GET_ADMIN
});

export const getAdminSuccess = (data: any) => ({
    type: actionTypes.GET_ADMIN_SUCCESS,
    payload: { data }
});

export const getAdminFailure = () => ({
    type: actionTypes.GET_ADMIN_FAILURE
});

export const getAdminTrails = () => {
    return (dispatch: any) => {
        dispatch(getAdminInit());
        axios.get(`${apiUrl}/history/admin-logs`, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getAdminSuccess(response.data.data));
                }
            })
            .catch(function (error) {
                dispatch(getAdminFailure());
            });
    };
};