import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IProjectCreate } from '../../../model/project';
import { AppNameInputField } from '../../../shared/custom-fields/app-name-input-field';
import { AppDescriptionOrRemarksField } from '../../../shared/custom-fields/app-description-remarks-field';

export interface ExternalActionProps {
  onClose: () => void;
  setSelected: (x: IProjectCreate) => void;
  setError: (x: any) => void;
  onSave: (x: IProjectCreate) => void;
}

export interface ExternalProps {
  open: boolean;
  error: any;
  selected: IProjectCreate;
}

export function ProjectDialog(props: ExternalActionProps & ExternalProps) {
  const { open, onClose, selected, setSelected, onSave, error, setError } = props;

  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
        <DialogTitle style={{ backgroundColor: '#084056', color: '#fff', marginBottom: '20px' }}>{selected.id ? "Update Study" : "Create Study"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          {selected.id && (
            <div className='identifier'>
              <label>Identifier : {selected.projCode}</label>
            </div>

          )}
          <AppNameInputField
            autoFocus={true}
            error={error.name}
            id="name"
            label="Name *"
            value={selected.projName}
            clearErrors={() => setError({
              ...error,
              name: {
                text: '',
                isError: false
              }
            })}
            onChange={(value: string) => {
              setSelected({
                ...selected,
                projName: value
              });
            }}
          />
          <AppDescriptionOrRemarksField
            error={error.description}
            id="description"
            label="Description *"
            value={selected.projDescription}
            clearErrors={() => setError({
              ...error,
              description: {
                text: '',
                isError: false
              }
            })}
            onChange={(value: string) => {
              setSelected({
                ...selected,
                projDescription: value
              });
            }}
          />
          {selected.id && (
            <AppDescriptionOrRemarksField
              error={error.remarks}
              id="remarks"
              label="Remarks *"
              value={selected.remarks}
              clearErrors={() => setError({
                ...error,
                remarks: {
                  text: '',
                  isError: false
                }
              })}
              onChange={(value: string) => {
                setSelected({
                  ...selected,
                  remarks: value
                });
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button style={{color: '#084056' }} className='button-text' onClick={() => onClose()}>Cancel</Button>
          <Button
            onClick={() => {
              onSave(selected);
            }}
            className='button-text'
            variant="contained"
            color="primary"
            style={{background: '#084056' }}
          >
            {selected.id ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}