import * as React from 'react';
import TextField from '@mui/material/TextField';
import { startWithANumberValidator, startWithASpaceValidator, nameValidation, specialSymbolsValidator } from '../../utitlities/FormValidator';
import InfoIcon from '@mui/icons-material/Info';

export interface ExternalActionProps {
    clearErrors: () => void;
    onChange: (value: string) => void;
}

export interface ExternalProps {
    id: string;
    label: string;
    error: any;
    value: string | undefined;
    autoFocus?: boolean;
}

export function AppMUIDInputField(props: ExternalActionProps & ExternalProps) {
    const { id, label, error, value, clearErrors, onChange, autoFocus } = props;
    const [showErrorInfo, setShowErrorInfo] = React.useState<boolean>(false);
    const [errorInfo, setErrorInfo] = React.useState<string>('');

    return (
        <>
            <TextField
                autoFocus={autoFocus}
                error={error && error.isError}
                margin="dense"
                id={id}
                label={label}
                required
                type="text"
                helperText={error && error.text}
                fullWidth
                value={value}
                inputProps={{ maxLength: 50 }}
                onChange={e => {
                    if (error) {
                        clearErrors();
                    }

                    if (specialSymbolsValidator(e.target.value)) {
                        clearErrors();
                        onChange(e.target.value);
                        setShowErrorInfo(false);
                        return;
                    }
                    // if (startWithANumberValidator(e.target.value)) {
                    //     setErrorInfo(`${label} cannot start with a number`);
                    //     setShowErrorInfo(true);
                    // }
                    if (startWithASpaceValidator(e.target.value)) {
                        setErrorInfo(`MUID cannot start with a space`);
                        setShowErrorInfo(true);
                    } else {
                        setErrorInfo(`Invalid MUID, Only Alphanumeric and space are allowed.`);
                        setShowErrorInfo(true);
                    }
                }}
                variant="outlined"
            />
            {(!error || !error.isError) && showErrorInfo && (
                <div className="d-flex align-items-center" style={{ color: '#d1a960', fontSize: '12px' }}>
                    <InfoIcon fontSize="small" /> {errorInfo}
                </div>
            )}
        </>
    );
}