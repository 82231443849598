import { StudyResultPage } from './study-result.component';
import { connect } from 'react-redux';
import { downloadWholeStudy, updateWell, commitStudy, downloadStudy, getStudy, getSignedUrl } from './../../api/study/studyAction';

const mapDispatchToProps = {
    updateWell,
    commitStudy,
    downloadStudy,
    getStudy,
    getSignedUrl,
    downloadWholeStudy
};

const mapStateToProps = (state: any) => ({
    processedStudy: state.studyPage.processedStudy,
    wellUpdated: state.studyPage.wellUpdated,
    singleStudy: state.studyPage.singleStudy,
    signedUrl: state.studyPage.signedUrl,
    isLoading: state.studyPage.isLoading,
    role: state.userPage.role
});

export const StudyResultContainer = connect(mapStateToProps, mapDispatchToProps)(StudyResultPage);
