import React, { useState, useEffect } from 'react';
import OAuth2Login from 'react-simple-oauth2-login';
import { connect } from 'react-redux';
import { getSsoToken, getUserDetail } from './../../api/user/userAction';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ReactLogo } from '../../tibil.svg';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Loading from './../../shared/Loading';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://tibilsolutions.com/">
                2022 Tibil & Co. Inc.,
            </Link>
        </Typography>
    );
}


const LoginForm = (props) => {
  const theme = createTheme();
  const { getSsoToken, ssoToken, getUserDetail, isLoggedIn } = props

  // useEffect(() => {
  //   getUserDetail(ssoToken.access_token);
  // }, [ssoToken]);

  
  if (isLoggedIn) {
    window.location.href = process.env.REACT_APP_BASE_HREF;
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie(cname) {
    let username = getCookie(cname);
    if (username == "") {
     return false;
    }
    return true;
  }

  function delete_cookie(cname) {
    document.cookie = cname +'=;domain=.tibilprojects.com;path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  useEffect(() => {
    if (checkCookie("at")){
      const token = getCookie("at");
      delete_cookie("at");
      setTimeout(() => getUserDetail(token), 100);
    } else {
      window.location.href = process.env.REACT_APP_MAIN_DOMAIN_URL;
    }
  }, []);

  const onFailure = (response) => console.error(response);
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Loading loading={true} />
          <Grid container justifyContent="center">
            <Grid item xs={4}>
              <Typography>We are logging you in..</Typography>
              <Typography>Please Wait...</Typography>
            </Grid>
          </Grid>
      </ThemeProvider>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </React.Fragment >
  );
};
const mapDispatchToProps = {
  getSsoToken,
  getUserDetail
};

const mapStateToProps = (state) => ({
  loginAttemptFailed: state.userPage.loginAttemptFailed,
  isLoggedIn: state.userPage.isLoggedIn,
  ssoToken: state.userPage.ssoToken
});

export const LoginFormContainer = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
