import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { IProjectCreate } from '../../../model/project';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { getDateTimeAsFormat } from '../../../utitlities/Time';
import { roles } from '../../../utitlities/roles';
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export interface ExternalActionProps {
  setSelected: (x: IProjectCreate) => void;
  setOpen: (x: boolean) => void;
  setOpenDelete: (x: boolean) => void;
}

export interface ExternalProps {
  projects: any;
  role: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'projName',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'projCode',
    numeric: false,
    disablePadding: false,
    label: 'Identifier',
  },
  {
    id: 'projDescription',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'studiesCount',
    numeric: true,
    disablePadding: false,
    label: 'No. Of Steps',
  },
  {
    id: 'projCode',
    numeric: false,
    disablePadding: false,
    label: 'Created Date/Time',
  },
  {
    id: 'createdByMuid',
    numeric: true,
    disablePadding: false,
    label: 'Created By',
  },
  {
    id: 'updatedAt',
    numeric: true,
    disablePadding: false,
    label: 'Last Updated Date/Time',
  },
  {
    id: 'updatedByMuid',
    numeric: true,
    disablePadding: false,
    label: 'Last Updated By',
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  role: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, role } =
    props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={{ width: '75px' }}></TableCell>
        {(role == roles.EXPERT_USER) || (role == roles.SUPER_ADMIN) ?
          <TableCell style={{ width: '75px' }}></TableCell>
          : ''}
      </TableRow>
    </TableHead>
  );
}

export function ProjectTable(props: ExternalActionProps & ExternalProps) {
  const { projects, setOpen, setSelected, setOpenDelete, role } = props;
  const [read, setRead] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<any>('projCode');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sliceDescription = (projectDescription: string): string => {
    if (projectDescription.length < 100) {
      return projectDescription;
    }

    return projectDescription.slice(0, 100) + '...';
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            role={role}
          />
          {/* <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Identifier</TableCell>
              <TableCell>Description</TableCell>
              <TableCell style={{ maxWidth: '75px' }}>No. Of Steps</TableCell>
              <TableCell>Created Date/Time</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Last Updated Date/Time</TableCell>
              <TableCell>Last Updated By</TableCell>
              <TableCell style={{ width: '75px' }}></TableCell>
              {(role == roles.EXPERT_USER) || (role == roles.SUPER_ADMIN) ?
                <TableCell style={{ width: '75px' }}></TableCell>
                : ''}
            </TableRow>
          </TableHead> */}
          <TableBody>
            {projects && Array.isArray(projects) && projects.length > 0 ? (
              stableSort(projects, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={row.active ? { backgroundColor: '#fff' } : { backgroundColor: '#eaeaea' }}
                    hover
                  >
                    <TableCell style={{ wordBreak: 'break-word' }} component="th" scope="row">
                      {row.active ? (<Link to={row.active ? `/study/${row.id}` : "#"}>{row.projName}</Link>) : row.projName}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>{row.projCode}</TableCell>
                    <TableCell style={{ wordBreak: 'break-word', width: '30%' }}>
                      {read !== row.id ? sliceDescription(row.projDescription) : row.projDescription}
                      {read !== row.id && row.projDescription.length > 100 && <span style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault(); e.stopPropagation(); setRead(row.id); }}><span>...&nbsp;</span><span style={{ color: '#503291' }}>Read More</span></span>}
                    </TableCell>
                    <TableCell align='center'>{row.studiesCount}</TableCell>
                    <TableCell align='center' style={{ wordBreak: 'break-word' }}>{getDateTimeAsFormat(row.createdAt)}</TableCell>
                    <TableCell align='center' style={{ wordBreak: 'break-word' }}>{row?.createdByMuid}</TableCell>
                    <TableCell align='center' style={{ wordBreak: 'break-word' }}>{getDateTimeAsFormat(row.updatedAt)}</TableCell>
                    <TableCell align='center' style={{ wordBreak: 'break-word' }}>{row?.updatedByMuid}</TableCell>
                    <TableCell align='center'>
                      <Tooltip title="Edit">
                        <IconButton sx={row.active ? { color: '#000', cursor: "pointer" } : { color: "#9a9a9a" }}
                          onClick={() => {
                            if (row.active) {
                              setSelected({
                                id: row.id,
                                projCode: row.projCode,
                                projName: row.projName,
                                projDescription: row.projDescription,
                                isActive: row.active,
                                remarks: ""
                              });
                              setOpen(true);
                            }
                          }}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {(role == roles.EXPERT_USER) || (role == roles.SUPER_ADMIN) ?
                      <TableCell align='center'>
                        <Tooltip title="Enable/Disable">
                          <Switch
                            {...label}
                            checked={row.active}
                            onChange={() => {
                              setSelected({
                                id: row.id,
                                projCode: row.projCode,
                                projName: row.projName,
                                projDescription: row.projDescription,
                                remarks: "",
                                isActive: row.active
                              });
                              setOpenDelete(true);
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      : ''}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell component="th" scope="row" colSpan={10} align="center">
                  No Study found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={projects && Array.isArray(projects) ? projects.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}