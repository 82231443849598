import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { IStudyCreate } from '../../../model/study';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { status } from '../../../utitlities/StudyStatus';

export interface ExternalActionProps {
  setSelected: (x: IStudyCreate) => void;
  setOpen: (x: boolean) => void;
}

export interface ExternalProps {
  studies: any;
  isUploading: boolean;
}

export function StudyTable(props: ExternalActionProps & ExternalProps) {
  const navigate = useNavigate();
  const { studies, setOpen, setSelected, isUploading } = props;
  const [read, setRead] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const sliceData = (x: string, l: number): string => {
    if (x.length < l) {
      return x;
    }
    return x.slice(0, l) + '...';
  }

  const openInNewTab = (url: any) => {
    var c = document.createElement("a");
    c.href = url;
    c.target = "_blank";
    c.click();
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ wordBreak: 'break-word' }}>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Identifier</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Microscope Series</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell colSpan={2} style={{ width: '200px' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studies && Array.isArray(studies) && studies.length > 0 ? (
              studies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, i: number) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  style={row.active ? { backgroundColor: '#fff' } : { backgroundColor: '#eaeaea' }}
                >
                  <TableCell style={{ wordBreak: 'break-word' }} component="th" scope="row" align="center">
                    {row.studName}
                  </TableCell>
                  <TableCell style={{ wordBreak: 'break-word' }} align="center">{row.studCode}</TableCell>
                  <TableCell style={{ wordBreak: 'break-word', width: '30%' }}>
                    {read != row.id ? sliceData(row.studDescription, 100) : row.studDescription}
                    {read != row.id && row.studDescription.length > 100 && <span style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault(); e.stopPropagation(); setRead(row.id); }}><span>...&nbsp;</span><span style={{ color: '#503291' }}>Read More</span></span>}
                  </TableCell>
                  <TableCell align="center" style={{ wordBreak: 'break-word' }}>{row.microscopeSeries[0] && row.microscopeSeries[0].msName}</TableCell>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span style={{ display: 'flex' }}>
                        <span style={{ alignSelf: 'end' }}>{row.status === "Failed" ? "Error" : row.status}</span>
                      </span>
                      {row.progress !== undefined && row.status === status.UPLOAD_IN_PROGRESS && (
                        <div>{Math.round(row.progress)} %</div>
                      )}
                      {row.status === "Error" && (
                        <Tooltip title={row.statusMessage} arrow>
                          <InfoOutlinedIcon />
                        </Tooltip>
                      )}
                      {row.status === "Failed" && (
                        <Tooltip title="Upload Failed" arrow>
                          <InfoOutlinedIcon />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit" arrow>
                      <EditIcon
                        style={(row.status == "Ready" || row.status == "Committed" || row.status == "Error" || row.status == "Failed" || row.status == "Upload Failed") ? {
                          color: '#000',
                          cursor: 'pointer'
                        } : {
                          color: '#9a9a9a',
                        }}
                        onClick={() => {
                          if (row.status == "Error" || row.status == "Failed" || row.status == "Ready" || row.status == "Committed" || row.status == "Upload Failed") {
                            setSelected({
                              id: row.id,
                              projectId: row.projectId,
                              studName: row.studName,
                              studCode: row.studCode,
                              studDescription: row.studDescription,
                              remarks: "",
                              microscopeSeries: Array.isArray(row.microscopeSeries) && row.microscopeSeries.length > 0 && row.microscopeSeries[0].id,
                              files: [],
                              isActive: row.active,
                              status: row.status
                            });
                            setOpen(true);
                          }
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Show Result" arrow>
                      <RemoveRedEyeIcon
                        style={(row.status == "Ready" || row.status == "Committed") ? {
                          color: '#000',
                          cursor: 'pointer'
                        } : {
                          color: '#9a9a9a',
                        }}
                        onClick={() => {
                          if (row.status == "Ready" || row.status == "Committed") {
                            isUploading ? openInNewTab(`${process.env.REACT_APP_MAIN_DOMAIN_URL}${process.env.REACT_APP_BASE_HREF}#/study/result/${row.id}`) : navigate(`/study/result/${row.id}`);
                          }
                        }}
                        sx={{ marginRight: '15px', marginLeft: '10px' }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell component="th" scope="row" colSpan={6} align="center">
                  No Step Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={studies && Array.isArray(studies) ? studies.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}