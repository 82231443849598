import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export interface ExternalActionProps {
  onClose: () => void;
  updateWell: (x: any) => void;
}

export interface ExternalProps {
  open: boolean;
  re: string;
  selected: any;
}

const EmptyError = {
  remarks: {
    isError: false,
    text: "",
  }
}

export function UpdateCellDialog(props: ExternalActionProps & ExternalProps) {
  const { open, onClose, re, selected, updateWell } = props;
  const [error, setError] = React.useState(EmptyError);
  const [sel, setSel] = React.useState({
    id: "",
    predictedValue: "",
    remarks: ""
  });
  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
        <DialogTitle sx={{ backgroundColor: '#084056', color: '#fff', marginBottom: '10px' }}>Update Well</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          {re == "DOUBTFUL" ? (
            <FormControl fullWidth sx={{ marginTop: '10px' }}>
              <InputLabel id="predicted">Predicted Value *</InputLabel>
              <Select
                fullWidth
                value={sel.predictedValue}
                onChange={e => {
                  setSel({ ...sel, predictedValue: e.target.value })
                }}
                labelId="predicted"
                id="cqa-multi-checkbox"
                label="Predicted Value *"
              >
                <MenuItem value="POSITIVE">Positive</MenuItem>
                <MenuItem value="NEGATIVE">Negative</MenuItem>
                <MenuItem value="DOUBTFUL">Doubtful</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Typography variant="h6">
              Predicted Value: {re}
            </Typography>
          )}
          <TextField
            error={error != null && error.remarks && error.remarks.isError}
            multiline
            margin="dense"
            id="remarks"
            label="Remarks *"
            type="text"
            rows={4}
            helperText={error != null && error.remarks && error.remarks.isError && error.remarks.text}
            value={sel.remarks}
            onChange={e => {
              if (/^([\w\:\;\'\"\(\)\,\.\&\-]+\s?)*$/.test(e.target.value)) {
                setError(EmptyError);
                setSel({
                  ...sel,
                  remarks: e.target.value
                });
              }
            }}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
          style={{color: '#084056' }}
            className='button-text'
            onClick={() => {
              setSel({
                id: "",
                predictedValue: "",
                remarks: ""
              });
              setError({
                ...error,
                remarks: {
                  isError: false,
                  text: "",
                }
              })
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button style={{background: '#084056' }}
            onClick={() => {
              if (sel.remarks && (sel.remarks.trim().length < 10 || sel.remarks.trim().length > 200)) {
                setError({
                  remarks: {
                    isError: true,
                    text: "Remarks must contain minimum 10 characters in length & maximun 200 characters allowed",
                  }
                })
                return;
              }
              if (typeof sel.remarks === 'string' && sel.remarks.trim().length === 0) {
                setError({
                  remarks: {
                    isError: true,
                    text: "Remarks is mandatory",
                  }
                })
                return;
              }
              updateWell({ predictionValue: sel.predictedValue, id: selected.id, remarks: sel.remarks });
              setSel({
                id: "",
                predictedValue: "",
                remarks: ""
              });
              onClose();
            }}
            className='button-text'
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}