import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IProjectCreate } from '../../../model/project';
import { AppDescriptionOrRemarksField } from '../../../shared/custom-fields/app-description-remarks-field';

export interface ExternalActionProps {
  onClose: () => void;
  deactivateProject: (x: IProjectCreate) => void;
  setError: (x: any) => void;
  setSelected: (x: IProjectCreate) => void;
}

export interface ExternalProps {
  open: boolean;
  error: any;
  selected: IProjectCreate;
}

const EmptyError = {
  name: {
    isError: false,
    text: "",
  },
  description: {
    isError: false,
    text: "",
  },
  remarks: {
    isError: false,
    text: "",
  }
}

export function ProjectDeleteDialog(props: ExternalActionProps & ExternalProps) {
  const { open, onClose, selected, deactivateProject, setSelected, error, setError } = props;

  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
        <DialogTitle>{selected.isActive ? "Deactivate Study" : "Activate Study"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to {selected.isActive ? "deactivate" : "activate"}?
          </DialogContentText>
          <AppDescriptionOrRemarksField
            error={error.remarks}
            id="remarks"
            label="Remarks *"
            value={selected.remarks}
            clearErrors={() => setError({
              ...error,
              remarks: {
                text: '',
                isError: false
              }
            })}
            onChange={(value: string) => {
              setSelected({
                ...selected,
                remarks: value
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button className='button-text' onClick={() => onClose()}>No</Button>
          <Button
            onClick={() => {
              deactivateProject({ ...selected, isActive: !selected.isActive });
            }}
            className='button-text'
            variant="contained"
            color="primary"
            style={{background: '#084056' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}