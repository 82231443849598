export function nameValidator(name: string): boolean {
    if (name !== '' && name !== undefined) {
        return /^[A-Za-z_][\w -]*([\w ])*$/.test(name);
    }

    return true;
}


export function specialSymbolsValidator(symbols: string): boolean {
    if (symbols !== '' && symbols !== undefined) {
        return /^[A-Za-z0-9][A-Za-z0-9 ]*([A-Za-z0-9 ])*$/.test(symbols);
    }
    return true;
}

export function nameValidation(symbols: string): boolean {
    if (symbols !== '' && symbols !== undefined) {
        return /^[A-Za-z][A-Za-z0-9 ]*([A-Za-z0-9 ])*$/.test(symbols);
    }
    return true;
}


export function emailValidator(email: string): boolean {
    if (email !== '' && email !== undefined) {
        return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email);
    }

    return true;
}


export function descriptionValidator(description: string): boolean {
    if (description !== '' && description !== undefined) {
        return /^[\w\:\;\'\"\(\)\,\.\&\-]+[\w\:\;\'\"\(\)\,\.\&\- ]*$/.test(description);
    }

    return true;
}

export function startWithANumberValidator(inputString: string): boolean {
    if (inputString !== '' && inputString !== undefined) {
        return /^\d/.test(inputString);
    }

    return true;
}

export function spaceValidator(inputString: string): boolean {
    if (inputString !== '' && inputString !== undefined) {
        return /^\S/.test(inputString);
    }

    return true;
}


export function studyNameValidator(symbols: string): boolean {
    if (symbols !== '' && symbols !== undefined) {
        return /[\!\@\#\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\:\;\|\~\`\_\-\/\\]/g.test(symbols);
    }

    return true;
}

export function numberWithSpaceValidator(inputString: string): boolean {
    if (inputString !== '' && inputString !== undefined) {
        return /^\d+[\d ]*$/.test(inputString);
    }

    return true;
}

export function startWithASpaceValidator(inputString: string): boolean {
    if (inputString !== '' && inputString !== undefined) {
        return /^\s/.test(inputString);
    }

    return true;
}
