import React, { useEffect } from 'react';
import { AuditTable } from './component/audit-table';
import Loading from './../../shared/Loading';
import { AppBreadcrumbContainer } from '../../shared/app-breadcrumb/app-breadcrumb.container';

export interface ExternalActionProps {
    getAuditTrails: () => void;
}

export interface ExternalProps {
    audits: any;
    isLoading: boolean;
}


export function AuditPage(props: ExternalActionProps & ExternalProps) {
    const { getAuditTrails, isLoading, audits } = props;

    useEffect(() => {
        getAuditTrails()
    }, []);


    return (
        <React.Fragment>
            <Loading loading={isLoading} />
            <AppBreadcrumbContainer breadcrumbs={[]} currentItem={'Audit Trails'} isUploading={false}></AppBreadcrumbContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
            </div>
            <AuditTable
                audits={audits}
            />
        </React.Fragment>
    );
}