import React from 'react';
import { Route, Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { rolePermittedRoutes } from '../../utitlities/rolePermittedRoutes';
import { roles } from '../../utitlities/roles';
import Unauthorized from '../../shared/Unauthorized';

const GuardedRoute = (props: any) => {
    let { children, isLoggedIn, role, logout } = props;
    const location = useLocation();

    const onLogout = () => {
        logout();
        return <Navigate to="/login" />;
    }

    const checkRoleAccess = () => {
        let routeName = location.pathname.split('/')[1]
        if (routeName == "") {
            return true
        }
        if (role == roles.TIBIL_IT) {
            return rolePermittedRoutes.TIBIL_IT.indexOf(routeName) > -1;
        } else if (role == roles.USER) {
            return rolePermittedRoutes.USER.indexOf(routeName) > -1;
        } else if (role == roles.EXPERT_USER) {
            return rolePermittedRoutes.EXPERT_USER.indexOf(routeName) > -1;
        } else {
            return rolePermittedRoutes.SUPER_ADMIN.indexOf(routeName) > -1;
        }
    }
    return isLoggedIn ? (checkRoleAccess() ? children : <Unauthorized />) : <Navigate to="/login" />;
}

export default GuardedRoute;
