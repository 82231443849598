import * as actionTypes from "./userActionTypes";
import axios from 'axios';
import { stringify } from "querystring";
import FormData from "form-data";
import store from '../../app/store';

const apiUrl = process.env.REACT_APP_API_URL;
const baseHref = process.env.REACT_APP_BASE_HREF;
const userId = store.getState().userPage.userId;


export const userLoginInit = () => ({
    type: actionTypes.USER_LOGIN
});

export const userLoginFailure = () => ({
    type: actionTypes.USER_LOGIN_FAILURE
});

export const userLoginSuccess = (data: any) => ({
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: { data }
});

export const userLogoutInit = () => ({
    type: actionTypes.USER_LOGOUT
});

export const userLogoutFailure = () => ({
    type: actionTypes.USER_LOGOUT_FAILURE
});

export const userLogoutSuccess = () => ({
    type: actionTypes.USER_LOGOUT_SUCCESS
});

export const login = (data: { username: string, password: string, grant_type: string }) => {
    const header = { headers: { Authorization: `Basic ${process.env.REACT_APP_AUTH_CODE}` } };
    const params = new FormData();
      params.append("grant_type", 'password');
      params.append("username", data.username);
      params.append("password", data.password);

    return (dispatch: any) => {
        dispatch(userLoginInit());
        axios.post(`${apiUrl}/login`, params, header)
            .then((response) => {
                console.log("Response: ", response);
                dispatch(getUserDetail(response.data.access_token));
                // localStorage.setItem('app-user-details', JSON.stringify(response.data.data));
                // dispatch(userLoginSuccess(response.data.data));
            })
            .catch(function (error) {
                dispatch(userLoginFailure());
            })
    };
};

export const getSsoTokenInit = () => ({
    type: actionTypes.GET_SSO_TOKEN
});

export const getSsoTokenFailure = () => ({
    type: actionTypes.GET_SSO_TOKEN_FAILURE
});

export const getSsoTokenSuccess = (data: any) => ({
    type: actionTypes.GET_SSO_TOKEN_SUCCESS,
    payload: { data }
});

export const getSsoToken = (code: string) => {
    const header = { headers: { Authorization: `Basic ${process.env.REACT_APP_AUTH_CODE}` } };
    const data = { grant_type: 'password', cognito_token: code };
    const params = new FormData();
    params.append("grant_type", 'password');
    params.append("cognito_token", code);

    return (dispatch: any) => {
        dispatch(getSsoTokenInit());
        axios.post(`${apiUrl}/login`, params, header)
            .then((response: any) => {
                localStorage.setItem('token', response.data.access_token);
                dispatch(getSsoTokenSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(getSsoTokenFailure());
            })
    };
};

export const getUserDetailInit = () => ({
    type: actionTypes.GET_USER_DETAIL
});

export const getUserDetailFailure = () => ({
    type: actionTypes.GET_USER_DETAIL_FAILURE
});

export const getUserDetailSuccess = (data: any) => ({
    type: actionTypes.GET_USER_DETAIL_SUCCESS,
    payload: { data }
});

export const getUserDetail = (code: string) => {
    const header = { headers: { Authorization: `Bearer ${code}` } };

    return (dispatch: any) => {
        dispatch(getUserDetailInit());
        axios.get(`${apiUrl}/user-info`, header)
            .then((response: any) => {
                localStorage.setItem("token", code);
                localStorage.setItem('app-user-details', JSON.stringify(response.data.data));
                dispatch(getUserDetailSuccess(response.data.data));
            })
            .catch(function (error) {
                dispatch(getUserDetailFailure());
            })
    };
};

// export const logout = (data: { email: string, password: string }) => {
//     return (dispatch: any) => {
//         dispatch(userLoginInit());
//         localStorage.removeItem('token');
//         localStorage.removeItem('app-user-details');
//         window.location.href = process.env.REACT_APP_MAIN_DOMAIN_URL || '/';
//         dispatch(userLogoutSuccess());
//     };
// };

export const logout = () => {
    const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem("token")}` } };

    return (dispatch: any) => {
        dispatch(userLoginInit());
        localStorage.removeItem('token');
        localStorage.removeItem('app-user-details');
        axios.post(`${apiUrl}/user-logout`, {}, header)
            .then((response: any) => {
                if (process.env.REACT_APP_IS_SSO == "true") {
                    window.location.href = process.env.REACT_APP_MAIN_DOMAIN_URL || '/';
                } else {
                    window.location.href = process.env.REACT_APP_BASE_HREF || '/bosch-vc';
                }
                dispatch(userLogoutSuccess());
            })
            .catch(function (error) {
                dispatch(userLogoutFailure());
            })
    };
};
