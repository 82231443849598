import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IUsermngtCreate } from '../../../model/usermngt';
import { AppDescriptionOrRemarksField } from '../../../shared/custom-fields/app-description-remarks-field';

export interface ExternalActionProps {
    onClose: () => void;
    deactivateUser: (x: IUsermngtCreate) => void;
    setError: (x: any) => void;
    setSelected: (x: IUsermngtCreate) => void;
}

export interface ExternalProps {
    open: boolean;
    error: any;
    selected: IUsermngtCreate;
}

export function UsermngtDeleteDialog(props: ExternalActionProps & ExternalProps) {
    const { open, onClose, selected, deactivateUser, setSelected, error, setError } = props;

    return (
        <div>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
                <DialogTitle>{selected.isActive ? "Deactivate User" : "Activate User"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to {selected.isActive ? "deactivate" : "activate"}?
                    </DialogContentText>
                    <AppDescriptionOrRemarksField
                        error={error.remarks}
                        id="remarks"
                        label="Remarks *"
                        value={selected.remarks}
                        clearErrors={() => setError({
                            ...error,
                            remarks: {
                                text: '',
                                isError: false
                            }
                        })}
                        onChange={(value: string) => {
                            setSelected({
                                ...selected,
                                remarks: value
                            });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className='button-text' onClick={() => onClose()}>No</Button>
                    <Button
                        onClick={() => {
                            deactivateUser({ ...selected, isActive: !selected.isActive });
                        }}
                        className='button-text'
                        variant="contained"
                        color="primary"
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}