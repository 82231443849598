import * as actionType from "./adminActionType";

export interface AdminReducerState {
    isLoading: boolean;
    isError: boolean;
    admins: [];
}

const initialState: AdminReducerState = {
    isError: false,
    isLoading: false,
    admins: []
};

export const adminReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionType.GET_ADMIN:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_ADMIN_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                admins: action.payload ? action.payload.data : [],
            };
        case actionType.GET_ADMIN_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };

        default:
            return state
    }
}
