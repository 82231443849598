import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { StudyTable } from './component/study-table';
import { StudyDialog } from './component/study-dialoge';
import { StudyDeleteDialog } from './component/study-delete-dialoge';
import AddIcon from '@mui/icons-material/Add';
import Loading from './../../shared/Loading';
import { IStudyCreate, emptyStudy } from '../../model/study';
import { Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { AppBreadcrumbContainer } from '../../shared/app-breadcrumb/app-breadcrumb.container';
import { WebSocketContext } from './../../shared/app-socket/WebSocket';
import { specialSymbolsValidator, numberWithSpaceValidator, startWithANumberValidator } from '../../utitlities/FormValidator';
import { Client } from 'stompjs';

const apiUrl = process.env.REACT_APP_API_URL;
export interface ExternalActionProps {
  getStudies: () => void;
  getByProject: (id: any) => void;
  createStudy: (x: IStudyCreate, stompClient: Client | null) => void;
  updateStudy: (x: IStudyCreate, stompClient: Client | null) => void;
  deactivateStudy: (x: IStudyCreate) => void;
  getMicroscopeSeries: () => void;
}

export interface ExternalProps {
  isLoading: boolean;
  isSaved: boolean;
  study: any;
  microscopeSeries: any;
  uploadCounter: number;
  userId: string
}

const EmptyError = {
  name: {
    isError: false,
    text: "",
  },
  description: {
    isError: false,
    text: "",
  },
  remarks: {
    isError: false,
    text: "",
  },
  fileUpload: {
    isError: false,
    text: "",
  }
}

export function StudyPage(props: ExternalActionProps & ExternalProps) {
  const {
    isLoading,
    createStudy,
    isSaved,
    updateStudy,
    getByProject,
    getMicroscopeSeries,
    study,
    microscopeSeries,
    uploadCounter,
    userId
  } = props;

  const { id } = useParams();
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({ ...emptyStudy, projectId: id });
  const [error, setError] = useState<any>(EmptyError);
  const ws = useContext(WebSocketContext);

  const callFailSocket = (x: any) => {
    const y = x.filter((item: any) => item.progress !== undefined && item.progress < 100);
    localStorage.setItem("failed", JSON.stringify(y));
  };

  const confirmationMessage = "You have files to be upload. Continue?";

  const useConfirmTabClose = (isUnsafeTabClose: boolean) => {
    React.useEffect(() => {
      const handleBeforeUnload = (event: any) => {
        if (isUnsafeTabClose) {
          console.log("Should show message.");
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [isUnsafeTabClose]);
  };

  const useTabClose = (isUnsafeTabClose: boolean) => {
    React.useEffect(() => {
      const handleUnload = () => {
        if (isUnsafeTabClose) {
          console.log("Calling Failed Socket.");
          callFailSocket(study);
        }
      };
      window.addEventListener("unload", handleUnload);
      return () =>
        window.removeEventListener("unload", handleUnload);
    }, [isUnsafeTabClose, study]);
  };

  useConfirmTabClose(uploadCounter > 0);
  useTabClose(uploadCounter > 0);

  const onClose = () => {
    setSelected(emptyStudy);
    setOpen(false);
    setError(EmptyError);
  }

  const onCloseDelete = () => {
    setSelected(emptyStudy);
    setOpenDelete(false);
    setError(EmptyError);
  }


  useEffect(() => {
    if (localStorage.getItem("failed") !== null) {
      setTimeout(function () {
        callFail()
      }, 3000);
    }
    getByProject(id);
    getMicroscopeSeries();
  }, []);

  const callFail = () => {
    const x = JSON.parse(localStorage.getItem("failed") || "{}");
    if (Array.isArray(x)) {
      x.forEach((indStudy: IStudyCreate) => {
        if (indStudy.progress !== undefined && indStudy.progress < 100) {
          ws.stompClient.send('/app/receive', undefined, JSON.stringify({ "topic": "UPLOAD", "subTopic": "UPLOAD_FAILED", "data": { "studyCode": indStudy.studCode, "user": userId } }));
        }
      });
      localStorage.removeItem("failed");
    }
  }

  useEffect(() => {
    if (isSaved && !isLoading) {
      getByProject(id);
      onClose();
      onCloseDelete();
    }
  }, [isSaved]);

  const validate = (data: IStudyCreate) => {
    let isInvalid = false;
    let errorNew: any = {};

    if (data.studName.trim().length === 0) {
      errorNew.name = {
        isError: true,
        text: "Step name is mandatory"
      };

      isInvalid = true;
    } else if (data.studName.trim().length < 3 || data.studName.trim().length > 50) {
      errorNew.name = {
        isError: true,
        text: "Step name must contain minimum 3 characters in length & maximum 50 characters allowed"
      };

      isInvalid = true;
    }
    if (data.studDescription.trim().length === 0) {
      errorNew.description = {
        isError: true,
        text: "Step description is mandatory"
      };

      isInvalid = true;
    } else if (data.studDescription.trim().length < 10 || data.studDescription.trim().length > 200) {
      errorNew.description = {
        isError: true,
        text: "Step description must contain minimum 10 characters in length & maximun 200 characters allowed"
      };

      isInvalid = true;
    }

    if (!data.microscopeSeries || data.microscopeSeries === '') {
      errorNew.microscopeSeries = {
        isError: true,
        text: "Microscope series is mandatory"
      };

      isInvalid = true;
    }

    if ((!data.id && data.files.length === 0) || ((data.status === 'Error' || data.status === 'Failed') && data.files.length === 0)) {
      errorNew.fileUpload = {
        isError: true,
        text: "Please choose the folder"
      };

      isInvalid = true;
    }

    if (data.id && typeof data.remarks === 'string' && data.remarks.trim().length === 0) {
      errorNew.remarks = {
        isError: true,
        text: "Remarks is mandatory"
      };

      isInvalid = true;
    } else if (data.id && ((data?.remarks && data.remarks.trim().length < 10) || (data?.remarks && data.remarks.trim().length > 200))) {
      errorNew.remarks = {
        isError: true,
        text: "Remarks must contain minimum 10 characters in length & maximun 200 characters allowed"
      };

      isInvalid = true;
    }

    if (error && error.fileUpload && error.fileUpload.isError) {
      errorNew.fileUpload = error.fileUpload;
      isInvalid = true;
    }

    if (!isInvalid) {
      data.projectId = id;
      if (data.id) {
        checkIfStudyExists(data, updateStudy);
      } else {
        checkIfStudyExists(data, createStudy);
      }
      onClose();
    } else {
      setError(errorNew);
    }
  }

  const checkIfStudyExists = (studyData: IStudyCreate, cb: (projectData: IStudyCreate, stompClient: Client | null) => void) => {
    let errorNew: any = {};
    const header = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    let queryParams = `studyName=${studyData.studName}`;
    if (studyData.id) {
      queryParams += `&id=${studyData.id}`;
    }

    axios.get(`${apiUrl}/study/is-exist?${queryParams}`, header)
      .then((response) => {
        cb(studyData, ws?.stompClient);
      })
      .catch(function (error) {
        errorNew.name = {
          isError: true,
          text: "Step name already exists"
        };
        setError(errorNew);
      });
  }

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <AppBreadcrumbContainer breadcrumbs={[]} currentItem={'Step'} isUploading={uploadCounter > 0}></AppBreadcrumbContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
        <Typography variant="h6">
          {Array.isArray(study) && study.length > 0 && study[0].projects && `${study[0].projects.projCode} - ${study[0].projects.projName}`}
        </Typography>
        <Button className='button-text' variant="contained" style={{background: '#084056' }} color="primary" onClick={() => setOpen(true)}>
          <AddIcon /> Create Step
        </Button>
      </div>
      <StudyTable
        studies={study}
        setSelected={setSelected}
        setOpen={() => setOpen(true)}
        isUploading={uploadCounter > 0}
      />
      {open && (
        <StudyDialog
          open={open}
          onClose={onClose}
          selected={selected}
          createStudy={validate}
          setSelected={setSelected}
          error={error}
          setError={setError}
          microscopeSeries={microscopeSeries}
        />
      )}
      <StudyDeleteDialog
        open={openDelete}
        onClose={onCloseDelete}
        selected={selected}
        deactivateStudy={validate}
        error={error}
        setError={setError}
        setSelected={setSelected}
      />
    </React.Fragment>
  );
}