export const CREATE_STUDY = "CREATE_STUDY";
export const CREATE_STUDY_FAILURE = "CREATE_STUDY_FAILURE";
export const CREATE_STUDY_SUCCESS = "CREATE_STUDY_SUCCESS";

export const GET_STUDY = "GET_STUDY";
export const GET_STUDY_FAILURE = "GET_STUDY_FAILURE";
export const GET_STUDY_SUCCESS = "GET_STUDY_SUCCESS";

export const GET_SIGNED_URL = "GET_SIGNED_URL";
export const GET_SIGNED_URL_FAILURE = "GET_SIGNED_URL_FAILURE";
export const GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS";

export const PROCESSED_STUDY = "PROCESSED_STUDY";
export const PROCESSED_STUDY_FAILURE = "PROCESSED_STUDY_FAILURE";
export const PROCESSED_STUDY_SUCCESS = "PROCESSED_STUDY_SUCCESS";

export const UPDATE_STUDY = "UPDATE_STUDY";
export const UPDATE_STUDY_FAILURE = "UPDATE_STUDY_FAILURE";
export const UPDATE_STUDY_SUCCESS = "UPDATE_STUDY_SUCCESS";

export const UPDATE_DEACTIVATE_STUDY = "UPDATE_DEACTIVATE_STUDY";
export const UPDATE_DEACTIVATE_STUDY_FAILURE = "UPDATE_DEACTIVATE_STUDY_FAILURE";
export const UPDATE_DEACTIVATE_STUDY_SUCCESS = "UPDATE_DEACTIVATE_STUDY_SUCCESS";

export const COMMIT_STUDY = "COMMIT_STUDY";
export const COMMIT_STUDY_FAILURE = "COMMIT_STUDY_FAILURE";
export const COMMIT_STUDY_SUCCESS = "COMMIT_STUDY_SUCCESS";

export const DOWNLOAD_STUDY = "DOWNLOAD_STUDY";
export const DOWNLOAD_STUDY_FAILURE = "DOWNLOAD_STUDY_FAILURE";
export const DOWNLOAD_STUDY_SUCCESS = "DOWNLOAD_STUDY_SUCCESS";

export const UPDATE_WELL = "UPDATE_WELL";
export const UPDATE_WELL_FAILURE = "UPDATE_WELL_FAILURE";
export const UPDATE_WELL_SUCCESS = "UPDATE_WELL_SUCCESS";

export const GET_STUDIES = "GET_STUDIES";
export const GET_STUDIES_FAILURE = "GET_STUDIES_FAILURE";
export const GET_STUDIES_SUCCESS = "GET_STUDIES_SUCCESS";

export const GET_BY_PROJECT = "GET_BY_PROJECT";
export const GET_BY_PROJECT_FAILURE = "GET_BY_PROJECT_FAILURE";
export const GET_BY_PROJECT_SUCCESS = "GET_BY_PROJECT_SUCCESS";

export const UPDATE_UPLOAD_COUNTER = "UPDATE_UPLOAD_COUNTER";

export const COMMIT_BACK_STUDY = "COMMIT_BACK_STUDY";
