import { AuditPage } from './audit.component';
import { connect } from 'react-redux';
import { getAuditTrails } from '../../api/audit/auditAction';

const mapDispatchToProps = {
    getAuditTrails
};

const mapStateToProps = (state: any) => ({
    error: state.auditPage.isError,
    isLoading: state.auditPage.isLoading,
    audits: state.auditPage.audits,
});

export const AuditContainer = connect(mapStateToProps, mapDispatchToProps)(AuditPage);