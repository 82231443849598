import WebSocket from './WebSocket';
import { connect } from 'react-redux';
import { updateStatusBySocket } from './../../api/study/studyAction';

const mapDispatchToProps = {
    updateStatusBySocket
};

const mapStateToProps = (state: any) => ({
    isLoggedIn: state.userPage.isLoggedIn
});


export const WebSocketContainer = connect(mapStateToProps, mapDispatchToProps)(WebSocket);
