import * as actionTypes from "./usermngtActionType";
import axios from 'axios';
import { IUsermngtCreate } from "../../model/usermngt";
import Swal from 'sweetalert2';
import store from '../../app/store';

const apiUrl = process.env.REACT_APP_API_URL;
const userId = store.getState().userPage.userId;

const header = { headers: { user: userId, Authorization: `Bearer ${localStorage.getItem("token")}` } };

export const createUserInit = () => ({
    type: actionTypes.CREATE_USER
});

export const createUserFailure = (data: any) => ({
    type: actionTypes.CREATE_USER_FAILURE,
    payload: { data }
});

export const createUserSuccess = (data: any) => ({
    type: actionTypes.CREATE_USER_SUCCESS,
    payload: { data }
});


export const createUser = (data: IUsermngtCreate) => {
    return (dispatch: any) => {
        dispatch(createUserInit());
        axios.post(`${apiUrl}/user-mangement/add`, data, header)
            .then((response) => {
                dispatch(createUserSuccess(response.data.data));
            })
            .catch(function (error) {
                if (error.response.data.data.errors) {
                    dispatch(createUserFailure(error.response.data.data.errors));
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Internal server error',
                        text: error.response.data.data
                    });
                }
            });
    };
};

export const updateUserInit = () => ({
    type: actionTypes.UPDATE_USER
});

export const updateUserFailure = () => ({
    type: actionTypes.UPDATE_USER_FAILURE
});

export const updateUserSuccess = (data: any) => ({
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload: { data }
});

export const updateUser = (data: IUsermngtCreate) => {
    return (dispatch: any) => {
        dispatch(updateUserInit());
        axios.put(`${apiUrl}/user-mangement/update`, data, header)
            .then((response) => {
                dispatch(updateUserSuccess(response.data));
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'warning',
                    text: error.response.data.data
                });
                dispatch(updateUserFailure());
            })
    };
};

export const deactivateUser = (data: IUsermngtCreate) => {
    return (dispatch: any) => {
        dispatch(updateUserInit());
        axios.delete(`${apiUrl}/user-mangement/deleteUser?muid=${data.muid}`, header)
            .then((response) => {
                dispatch(updateUserSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateUserFailure());
            });
    };
};

export const getUsersInit = () => ({
    type: actionTypes.GET_USERS
});

export const getUsersSuccess = (data: any) => ({
    type: actionTypes.GET_USERS_SUCCESS,
    payload: { data }
});

export const getUsersFailure = () => ({
    type: actionTypes.GET_USERS_FAILURE
});

export const getUsers = () => {
    return (dispatch: any) => {
        dispatch(getUsersInit());
        axios.get(`${apiUrl}/user-mangement/getAll`, header)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getUsersSuccess(response.data.data));
                }
            })
            .catch(function (error) {
                dispatch(getUsersFailure());
            });
    };
};

export const clearErrors = () => {
    return (dispatch: any) => {
        dispatch((() => ({
            type: actionTypes.CLEAR_ERRORS
        }))());
    };
}
