import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IStudyCreate } from '../../../model/study';

export interface ExternalActionProps {
  onClose: () => void;
  commitStudy: (x: any) => void;
}

export interface ExternalProps {
  open: boolean;
  studyId: any;
}

const EmptyError = {
  remarks: {
    isError: false,
    text: "",
  }
}

export function CommitReportDialog(props: ExternalActionProps & ExternalProps) {
  const { open, onClose, commitStudy, studyId } = props;
  const [remark, setRemark] = React.useState("");
  const [error, setError] = React.useState(EmptyError);

  return (
    <div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
        <DialogTitle sx={{ backgroundColor: '#084056', color: '#fff', marginBottom: '10px' }}>Commit Step</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once step is committed, the prediction values can not be updated<br />
            Do you really want to commit?
          </DialogContentText>
          <TextField
            error={error != null && error.remarks && error.remarks.isError}
            multiline
            margin="dense"
            id="remarks"
            label="Remarks *"
            type="text"
            rows={4}
            helperText={error != null && error.remarks && error.remarks.isError && error.remarks.text}
            value={remark}
            onChange={e => {
              if (/^([\w\:\;\'\"\(\)\,\.\&\-]+\s?)*$/.test(e.target.value)) {
                setError(EmptyError);
                setRemark(e.target.value);
              }
            }}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
          style={{color: '#084056' }}
            className='button-text'
            onClick={() => {
              setRemark("");
              setError({
                ...error,
                remarks: {
                  isError: false,
                  text: "",
                }
              })
              onClose();
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              if (remark && (remark.trim().length < 10 || remark.trim().length > 200)) {
                setError({
                  remarks: {
                    isError: true,
                    text: "Remarks must contain minimum 10 characters in length & maximun 200 characters allowed",
                  }
                })
                return;
              }
              if (typeof remark === 'string' && remark.trim().length === 0) {
                setError({
                  remarks: {
                    isError: true,
                    text: "Remarks is mandatory",
                  }
                })
                return;
              }
              commitStudy({ studyCode: studyId, remarks: remark });
              setRemark("");
              onClose();
            }}
            className='button-text'
            variant="contained"
            color="primary"
            style={{background: '#084056' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}