export function ColorDot(props: any) {
  const { onSelect, isActive, color } = props;

  return (
      <div
        onClick={onSelect}
        style={isActive ? 
          {display: "flex", alignItems: "center", justifyContent: "center", boxShadow: `5px 5px 8px ${color == "POSITIVE" ? "green" : (color == "NEGATIVE" ? "red" : "#c9b81c")}`, width: '30px', height: '30px', backgroundColor: color == "POSITIVE" ? "green" : (color == "NEGATIVE" ? "red" : "#c9b81c"), borderRadius: '50%', border: '2px solid #000'} : { display: "flex", alignItems: "center", justifyContent: "center", cursor: 'pointer', width: '25px', height: '25px', backgroundColor: color == "POSITIVE" ? "green" : (color == "NEGATIVE" ? "red" : "#c9b81c"), borderRadius: '50%', border: '1px solid #dadada'}}>
        <span style={{ color: "#fff" }}>
          {color == "POSITIVE" ? "X" : (color == "NEGATIVE" ? "O" : "?")}
        </span>
      </div>
  );
}