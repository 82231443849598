export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";

export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_FAILURE = "GET_PROJECT_FAILURE";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";

export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const GET_PROJECT_DETAILS_FAILURE = "GET_PROJECT_DETAILS_FAILURE";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";