import React, { useState } from 'react';
import { ReactComponent as ReactLogo } from '../../tibil.svg';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { emailValidator } from '../../utitlities/FormValidator';
import  './Login.css';
import { emit } from 'process';
function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://tibilsolutions.com/">
                2023 Tibil solutions.
            </Link>
        </Typography>
    );
}

const theme = createTheme();

export interface ExternalActionProps {
    login: (data: { username: string, password: string, grant_type: string }) => void;
}

export interface ExternalProps {
    loginAttemptFailed: boolean,
    isLoggedIn: boolean
}

const EmptyError = {
    email: {
        isError: false,
        text: "",
    },
    password: {
        isError: false,
        text: "",
    }
};

export function LoginPage(props: ExternalActionProps & ExternalProps) {
    const { loginAttemptFailed, isLoggedIn, login } = props;
    const [error, setError] = useState<any>(EmptyError);

    if (isLoggedIn) {
        window.location.href = process.env.REACT_APP_BASE_HREF || '/bosch-vc';
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // const data = new FormData(event.currentTarget);
        // let email = data.get('email')?.toString();
        // let password = data.get('password')?.toString();
       
        const data =  cred.find(el => el.email === currentEmail);
        const email = data?.email;
        const password = data?.value;
        let errorNew: any = {};
        let isInvalid = false;

        if (!email || email.trim().length === 0) {
            errorNew.email = {
                isError: true,
                text: 'Email is mandatory'
            }

            isInvalid = true;
        } else if (!emailValidator(email)) {
            errorNew.email = {
                isError: true,
                text: "You have entered an invalid email id!"
            };

            isInvalid = true;
        }

        if (!password || password.trim().length === 0) {
            errorNew.password = {
                isError: true,
                text: 'Password is mandatory'
            }

            isInvalid = true;
        }

        setError(errorNew);

        if (!isInvalid) {
            login({ username: email as string, password: password as string, grant_type: 'password' });
        }
    };

    const cred = [

        { email: 'super_admin@tibilsolutions.com', value: 'Tibil@123' },
     
        { email: 'expert_user@tibilsolutions.com', value: 'Tibil@123' },
     
        { email: 'tibil_it@tibilsolutions.com', value: 'Tibil@123' },
     
      ];

//       const [email, setEmail] = React.useState('super_admin@tibilsolutions.com');

//  const handleChange = (event) => {

//    setEmail(event.target.value);
//  }
const [currentEmail, setCurrentEmail] = useState(cred[0].email)
  
  const changeEmail = (newEmail: any) => {
    setCurrentEmail(newEmail)
    console.log('change Email', newEmail, 'curr', currentEmail);
  }
    return (
        <React.Fragment>
            <Grid>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Paper elevation={10} sx={{ padding: '1rem' }}>
                                <Typography className="login-container d-flex justify-content-center" variant="h6" component="div" sx={{ mb: 3, mt: 3 }}>
                                    <a target="_blank" href='https://tibilsolutions.com/'><ReactLogo className="app-logo" /></a>
                                </Typography>
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                    {/* <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Id"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        error={error != null && error.email && error.email.isError}
                                        helperText={error != null && error.email && error.email.isError && error.email.text}
                                        onChange={e => {
                                            setError({
                                                email: {
                                                    isError: false,
                                                    text: ""
                                                },
                                                password: error.password
                                            });
                                        }}
                                    /> */}
                                    <select className='selectForm' name='Email' onChange={(event) => changeEmail(event.target.value)}
                                         value={currentEmail}>

                                            {cred.map((option) => (

                                            <option className='cursorPointer' value={option.email}>{option.email}</option>

                                            ))}
                                            

                                            </select>
                                    <TextField className='pass'
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        error={error != null && error.password && error.password.isError}
                                        helperText={error != null && error.password && error.password.isError && error.password.text}
                                        onChange={e => {
                                            setError({
                                                email: error.email,
                                                password: {
                                                    isError: false,
                                                    text: ""
                                                }
                                            });
                                        }}
                                    />
                                    {loginAttemptFailed && (
                                        <p style={{ color: '#d32f2f', marginBottom: 0 }}>Invalid credentials</p>
                                    )}
                                    <Button
                                    style={{background: '#084056'}}
                                        className='button-text'
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, background: '#503291' }}
                                    >
                                        Sign In
                                    </Button>
                                </Box>
                            </Paper>
                        </Box>
                    </Container>
                </ThemeProvider>
            </Grid>
            <Copyright sx={{ mt: 3, mb: 4 }} />
        </React.Fragment >
    );
}
