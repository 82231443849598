import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IUsermngtCreate } from '../../../model/usermngt';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { descriptionValidator, spaceValidator } from '../../../utitlities/FormValidator';
import { AppUsermngtInputField } from '../../../shared/custom-fields/app-usermngt-input-field';
import { AppDescriptionOrRemarksField } from '../../../shared/custom-fields/app-description-remarks-field';
import { AppMUIDInputField } from '../../../shared/custom-fields/app-muid-input-field';


export interface ExternalActionProps {
    onClose: () => void;
    setSelected: (x: IUsermngtCreate) => void;
    setError: (x: any) => void;
    onSave: (x: IUsermngtCreate) => void;
}

export interface ExternalProps {
    open: boolean;
    error: any;
    selected: IUsermngtCreate;
}

const EmptyError = {
    muid: {
        isError: false,
        text: "",
    },
    name: {
        isError: false,
        text: "",
    },
    lastName: {
        isError: false,
        text: "",
    },
    email: {
        isError: false,
        text: "",
    },
    role: {
        isError: false,
        text: "",
    },
    remarks: {
        isError: false,
        text: "",
    }
}

export function UserManagementDialog(props: ExternalActionProps & ExternalProps) {
    const { open, onClose, selected, setSelected, onSave, error, setError } = props;

    const roles = [
        { label: "Tibil IT", id: 1 },
        { label: "User", id: 2 },
        { label: "Expert User", id: 3 },
    ];

    const [rolesValue, setRolesValue] = useState<{ label: string }>();

    useEffect(() => {
        if (selected.id) {
            if (selected.role) {
                setRolesValue(roles.find(role => role.label === selected.role));
            }
        }
    }, []);

    return (
        <div>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => onClose()}>
                <DialogTitle style={{ backgroundColor: '#084056', color: '#fff', marginBottom: '20px' }}>{selected.id ? "Update User" : "Add User"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    {selected.id ?
                        <Typography variant="h6" sx={{ marginBottom: '10px' }}>MUID : {selected.muid}</Typography>
                        :
                        // <TextField
                        //     autoFocus
                        //     margin="dense"
                        //     error={error != null && error.muid && error.muid.isError}
                        //     id="muId"
                        //     label="MU Id"
                        //     type="text"
                        //     required
                        //     fullWidth
                        //     helperText={error != null && error.muid && error.muid.isError && error.muid.text}
                        //     value={selected.muid}
                        //     onChange={e => {
                        //         if (spaceValidator(e.target.value)) {
                        //             setError(EmptyError);
                        //             setSelected({
                        //                 ...selected,
                        //                 muid: e.target.value
                        //             });
                        //         }
                        //     }}
                        //     variant="outlined"
                        // />
                        <AppMUIDInputField
                            error={error.muid}
                            id="muId"
                            label="MUID"
                            value={selected.muid}
                            clearErrors={() => setError({
                                ...error,
                                muid: {
                                    text: '',
                                    isError: false
                                }
                            })}
                            onChange={(value: string) => {
                                setSelected({
                                    ...selected,
                                    muid: value
                                });
                            }}
                        />
                    }
                    {selected.id && (
                        <Typography variant="h6" sx={{ marginBottom: '10px' }}>Email : {selected.email}</Typography>
                    )}
                    {/* <TextField
                        error={error != null && error.name && error.name.isError}
                        margin="dense"
                        id="firstName"
                        label="First Name"
                        required
                        type="text"
                        helperText={error != null && error.name && error.name.isError && error.name.text}
                        fullWidth
                        value={selected.firstName}
                        onChange={e => {
                            if (spaceValidator(e.target.value)) {
                                setError(EmptyError);
                                setSelected({
                                    ...selected,
                                    firstName: e.target.value
                                });
                            }
                        }}
                        variant="outlined"
                    /> */}
                    <AppUsermngtInputField
                        error={error.name}
                        id="firstName"
                        label="First Name"
                        value={selected.firstName}
                        clearErrors={() => setError({
                            ...error,
                            name: {
                                text: '',
                                isError: false
                            }
                        })}
                        onChange={(value: string) => {
                            setSelected({
                                ...selected,
                                firstName: value
                            });
                        }}
                    />
                    {/* <TextField
                        error={error != null && error.lastName && error.lastName.isError}
                        multiline
                        margin="dense"
                        id="lastName"
                        label="Last Name"
                        required
                        type="text"
                        helperText={error != null && error.lastName && error.lastName.isError && error.lastName.text}
                        value={selected.lastName}
                        onChange={e => {
                            if (spaceValidator(e.target.value)) {
                                setError(EmptyError);
                                setSelected({
                                    ...selected,
                                    lastName: e.target.value
                                });
                            }
                        }}
                        fullWidth
                        variant="outlined"
                    /> */}
                    <AppUsermngtInputField
                        error={error.lastName}
                        id="lastName"
                        label="Last Name"
                        value={selected.lastName}
                        clearErrors={() => setError({
                            ...error,
                            lastName: {
                                text: '',
                                isError: false
                            }
                        })}
                        onChange={(value: string) => {
                            setSelected({
                                ...selected,
                                lastName: value
                            });
                        }}
                    />
                    {!selected.id && (
                        <TextField
                            error={error != null && error.email && error.email.isError}
                            margin="dense"
                            id="email"
                            label="Email"
                            required
                            type="text"
                            helperText={error != null && error.email && error.email.isError && error.email.text}
                            fullWidth
                            value={selected.email}
                            onChange={e => {
                                if (spaceValidator(e.target.value)) {
                                    setError(EmptyError);
                                    setSelected({
                                        ...selected,
                                        email: e.target.value
                                    });
                                }
                            }}
                            variant="outlined"
                        />
                    )}
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        <Autocomplete
                            id="roles"
                            options={roles}
                            getOptionLabel={(option: any) => option.label}
                            defaultValue={rolesValue}
                            filterSelectedOptions
                            onChange={(event, value: any) => {
                                setError(EmptyError);
                                setSelected({
                                    ...selected,
                                    role: value ? value.label : ''
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    error={error != null && error.role && error.role.isError}
                                    helperText={error != null && error.role && error.role.isError && error.role.text}
                                    {...params}
                                    label="Role *"
                                    placeholder="Select Role"
                                />
                            )}
                        />
                    </FormControl>
                    {selected.id && (
                        <AppDescriptionOrRemarksField
                            error={error.remarks}
                            id="remarks"
                            label="Remarks *"
                            value={selected.remarks}
                            clearErrors={() => setError({
                                ...error,
                                remarks: {
                                    text: '',
                                    isError: false
                                }
                            })}
                            onChange={(value: string) => {
                                setSelected({
                                    ...selected,
                                    remarks: value
                                });
                            }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button style={{color: '#084056' }} className='button-text' onClick={() => onClose()}>Cancel</Button>
                    <Button
                        onClick={() => {
                            onSave(selected);
                        }}
                        style={{background: '#084056' }}
                        className='button-text'
                        variant="contained"
                        color="primary"
                    >
                        {selected.id ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}