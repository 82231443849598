import React from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { getDateTimeAsFormat } from '../../../utitlities/Time';

const columns = [
    {
        name: "message",
        label: "Operation",
        options: {
            filter: false,
            sort: true,
            filterOptions: {
                renderValue: (v: any) => v ? v.replace(/<newline>/g, ',') : ''
            },
            customBodyRender: (value: any) => {
                let split = value.split(/\r?\<newline>/)
                return (
                    <div>
                        {split.map((data: any) => {
                            return (
                                <p>{data}</p>
                            )
                        })}
                    </div>
                )
            },
        }
    },
    {
        name: "lastUpdatedByMuid",
        label: "User",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "updatedAt",
        label: "Date and Time",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "remarks",
        label: "Remarks",
        options: {
            filter: false,
            sort: true,
        }
    }
];

const options = {
    viewColumns: false,
    print: false,
    download: true,
    downloadOptions: {
        filename: 'audit-trails.csv', separator: ','
    },
    textLabels: {
        body: {
            noMatch: 'No Records Found',
        }
    },
    selectableRowsHideCheckboxes: true,
    onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
        data = data.map((obj: any) => {
            const temp = [
                obj.data[0].split(/\r?\<newline>/),
                obj.data[1],
                obj.data[2],
                obj.data[3],
            ];
            return { data: temp };
        });
        return "\uFEFF" + buildHead(columns) + buildBody(data);
    }
};

export interface ExternalProps {
    audits: any;
}

export function AuditTable(props: ExternalProps) {
    const { audits } = props;

    const data = audits.map((data: any) => ({ ...data, message: data.message, updatedBy: data?.lastUpdatedByMuid, updatedAt: getDateTimeAsFormat(data.updatedAt), remarks: data.remarks }))

    const downloadPdf = () => {
        const doc = new jsPDF()
        doc.text("Audit Trails", 20, 10)
        autoTable(doc, {
            columns: columns.map(col => ({ ...col, header: col.label, dataKey: col.name })),
            body: audits.map((data: any) => ({ ...data, message: data.message.split(/\r?\<newline>/), updatedBy: data?.lastUpdatedByMuid, updatedAt: getDateTimeAsFormat(data.updatedAt), remarks: data.remarks }))
        })
        doc.save('audit-trails.pdf')
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography variant="h6"></Typography>
                <Button style={{background: '#084056' }} className='button-text' variant="contained" color="primary" onClick={downloadPdf}>
                    Export Pdf
                </Button>
            </div>
            < MUIDataTable
                title={"Audit Trails"}
                data={data}
                columns={columns}
                options={options}
            />
        </React.Fragment>
    );
}

