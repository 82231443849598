import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { ColorDot } from './color-dot';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { getDateTimeAsFormat } from '../../../utitlities/Time';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { roles } from '../../../utitlities/roles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

declare global {
  interface Window {
    loadImage: (x: any) => void;
    tiffNewImg: any;
    tiffNewImgErr: boolean;
  }
}

const i = 0;

export function StudyResultView(props: any) {
  const { downloadStudy, isLoading, getSignedUrl, signedUrl, setCellOpen, selectedPlate, setSelectedPlate, setRe, processedStudy, selected, setSelected, studyCode, studyStatus, downloadImage, role } = props;
  let myInterval: any;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [timer, setTimer] = React.useState<any>({ y: 0 });
  const [loadedTiff, setLoadedTiff] = React.useState<any>("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getWellCode = (i: number) => {
    const wellCol = ["A", "B", "C", "D", "E", "F", "G", "H"];
    const divVal: number = parseInt((i / 12).toString());
    const wellC1 = wellCol[divVal];
    const wellNo = (i % 12) + 1;
    return wellC1 + wellNo;
  }

  const getCount = (i: number) => {
    let k: number = 0;
    if (processedStudy && Array.isArray(processedStudy) && selectedPlate.show && processedStudy[selectedPlate.i].plateName) {
      const x = processedStudy[selectedPlate.i].data;
      for (let j = i; j < x.length; j = j + 12) {
        let x = processedStudy[selectedPlate.i].data[j].updatedPrediction ? processedStudy[selectedPlate.i].data[j].updatedPrediction : processedStudy[selectedPlate.i].data[j].predictedValue
        if (x == "POSITIVE") {
          k++;
        }
      }
    }
    return k;
  }

  const downloadFile = (code: any, name: any) => {
    downloadStudy({ studyCode: code, plateName: name });
  }

  async function testLoad(url: any) {
    if (typeof window != "undefined") {
      window.loadImage(url);
    }
  }

  React.useEffect(() => {
    if (signedUrl !== "") {
      testLoad(signedUrl);
    }
  }, [signedUrl]);

  const checkURL = () => {
    let i: number = 0;
    let myInterval = setInterval(() => {
      i = i + 1;
      setTimer({ y: i });
    }, 1000);
  }

  React.useEffect(() => {
    if (window.tiffNewImg !== loadedTiff) {
      setLoadedTiff(window.tiffNewImg);
    }
  }, [timer]);

  return (
    <div>
      <Box sx={{ padding: '10px 40px' }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ wordBreak: 'break-word' }}>
                    <TableCell align="center">Well plate Code</TableCell>
                    <TableCell align="center">Total Images</TableCell>
                    <TableCell align="center">Positive</TableCell>
                    <TableCell align="center">Negative</TableCell>
                    <TableCell align="center">Doubtful</TableCell>
                    <TableCell align="center">Test Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processedStudy && Array.isArray(processedStudy) && processedStudy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, i: number) => {
                    return (
                      <TableRow>
                        <TableCell align="center">
                          <span style={{ cursor: 'pointer', color: '#007aff', textDecoration: 'underline' }} onClick={() => {
                            setSelectedPlate({ i, show: true });
                            setSelected(null);
                          }} >
                            {item.plateName}
                          </span>
                        </TableCell>
                        <TableCell align="center">{item.data.length}</TableCell>
                        <TableCell align="center">
                          {item.data.filter((item: any) => {
                            return item.updatedPrediction ? item.updatedPrediction === "POSITIVE" : item.predictedValue === "POSITIVE";
                          }).length}
                        </TableCell>
                        <TableCell align="center">
                          {item.data.filter((item: any) => {
                            return item.updatedPrediction ? item.updatedPrediction === "NEGATIVE" : item.predictedValue === "NEGATIVE";
                          }).length}
                        </TableCell>
                        <TableCell align="center">
                          {item.data.filter((item: any) => {
                            return item.updatedPrediction ? item.updatedPrediction === "DOUBTFUL" : item.predictedValue === "DOUBTFUL";
                          }).length}
                        </TableCell>
                        <TableCell align="center" style={{ wordBreak: 'break-word' }}>
                          {item.data && Array.isArray(item.data) && item.data.length > 0 && getDateTimeAsFormat(item.data[0].predictionTimestamp)}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={processedStudy && Array.isArray(processedStudy) ? processedStudy.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
          {selectedPlate.show && (
            <React.Fragment>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {processedStudy && Array.isArray(processedStudy) && selectedPlate.show && processedStudy[selectedPlate.i].plateName}
                </Typography>
                <Box sx={{ border: '1px solid #5a5a5a', maxHeight: '600px', overflow: 'auto', backgroundColor: '#f9f9f9', padding: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>&nbsp;</Grid>
                        <Grid item xs={12}><strong>A</strong></Grid>
                        <Grid item xs={12}><strong>B</strong></Grid>
                        <Grid item xs={12}><strong>C</strong></Grid>
                        <Grid item xs={12}><strong>D</strong></Grid>
                        <Grid item xs={12}><strong>E</strong></Grid>
                        <Grid item xs={12}><strong>F</strong></Grid>
                        <Grid item xs={12}><strong>G</strong></Grid>
                        <Grid item xs={12}><strong>H</strong></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={1}><strong>1</strong></Grid>
                        <Grid item xs={1}><strong>2</strong></Grid>
                        <Grid item xs={1}><strong>3</strong></Grid>
                        <Grid item xs={1}><strong>4</strong></Grid>
                        <Grid item xs={1}><strong>5</strong></Grid>
                        <Grid item xs={1}><strong>6</strong></Grid>
                        <Grid item xs={1}><strong>7</strong></Grid>
                        <Grid item xs={1}><strong>8</strong></Grid>
                        <Grid item xs={1}><strong>9</strong></Grid>
                        <Grid item xs={1}><strong>10</strong></Grid>
                        <Grid item xs={1}><strong>11</strong></Grid>
                        <Grid item xs={1}><strong>12</strong></Grid>
                        {Array.isArray(processedStudy[selectedPlate.i].data) && processedStudy[selectedPlate.i].data.map((item: any, i: number) => {
                          return (
                            <Grid item xs={1} key={i}>
                              <ColorDot
                                color={item.updatedPrediction ? item.updatedPrediction : item.predictedValue}
                                onSelect={() => {
                                  checkURL();
                                  getSignedUrl(studyCode, item.imageName);
                                  setRe(item.updatedPrediction ? item.updatedPrediction : item.predictedValue);
                                  setSelected({ ...item, si: i });
                                }}
                                isActive={selected && (selected.id == item.id)}
                              />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>Tot</Grid>
                    <Grid item xs={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={1}><strong>{getCount(0)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(1)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(2)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(3)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(4)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(5)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(6)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(7)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(8)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(9)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(10)}</strong></Grid>
                        <Grid item xs={1}><strong>{getCount(11)}</strong></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                {selected && (
                  <div style={{ display: 'flex' }}>
                    <Box sx={{ padding: '20px', border: '1px solid #dadada', width: '100%' }}>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableRow>
                            <TableCell width="30%" align="center" style={{ backgroundColor: '#084056', color: 'white' }}>Well Code</TableCell>
                            <TableCell align="center">
                              <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                <span>{getWellCode(selected.si)}</span>
                                {!window.tiffNewImgErr ? (
                                  <div>
                                    {!isLoading && loadedTiff !== null ? (
                                      <VisibilityIcon
                                        sx={{ cursor: "pointer", marginRight: "5px" }}
                                        onClick={() => {
                                          setIsOpen(true);
                                        }}
                                      />
                                    ) : (
                                      <CircularProgress size={15} />
                                    )}
                                    {!isLoading && <DownloadForOfflineIcon sx={{ cursor: "pointer" }} onClick={() => downloadImage(signedUrl)} />}
                                  </div>
                                ) : (
                                  <Tooltip arrow title="No Image Found">
                                    <InfoOutlinedIcon />
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" style={{ backgroundColor: '#084056', color: 'white' }}>Image</TableCell>
                            <TableCell align="center">{selected.imageName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" style={{ backgroundColor: '#084056', color: 'white' }}>Confidence Score</TableCell>
                            <TableCell align="center">{selected.confidenceScore}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" style={{ backgroundColor: '#084056', color: 'white' }}>Predicted Value</TableCell>
                            <TableCell align="center">{selected.predictedValue}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" style={{ backgroundColor: '#084056', color: 'white' }}>Updated Predicted Value</TableCell>
                            <TableCell align="center">{selected.updatedPrediction}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" style={{ backgroundColor: '#084056', color: 'white' }}>User</TableCell>
                            <TableCell align="center">{selected.updatedByMuid}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" style={{ backgroundColor: '#084056', color: 'white' }}>Date and Time</TableCell>
                            <TableCell align="center">{getDateTimeAsFormat(selected.updatedAt)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" style={{ backgroundColor: '#084056', color: 'white' }}>Remarks</TableCell>
                            <TableCell align="center">{selected.remarks}</TableCell>
                          </TableRow>
                          {(role == roles.EXPERT_USER) || (role == roles.SUPER_ADMIN) || (role == roles.USER) ?
                            <TableRow>
                              <TableCell colSpan={2} align="right">
                                <Button
                                style={{background: '#084056'}}
                                  disabled={studyStatus === "Committed"}
                                  onClick={() => setCellOpen(true)}
                                  className='button-text'
                                  variant="contained"
                                  color="primary"
                                  sx={{ marginRight: '5px' }}
                                >
                                  Update
                                </Button>
                              </TableCell>
                            </TableRow>
                            : ''}
                        </Table>
                      </TableContainer>
                    </Box>
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: '20px', textAlign: "center" }}>
                  <Button  style={{background: '#084056' }}className='button-text' onClick={() => downloadFile(studyCode, processedStudy[selectedPlate.i].plateName)} variant="contained" color="primary" sx={{ marginRight: '5px' }}>
                    Download Report
                  </Button>
                </div>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Box>

      {isOpen && (
        <Lightbox
          mainSrc={selected && loadedTiff}
          imageTitle={selected.imageName}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}